import React, { Component } from "react";
import styled from "styled-components";
import { device } from "../device.js";

import { Route } from "react-router-dom";

import Home from "../pages/home";
import About from "../pages/about";
import Work from "../pages/work";
import Slumber from "../pages/slumber";
import Appetizr from "../pages/appetizr";
import Talky from "../pages/talkyblocks";

const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: scroll;
  position: absolute;
  z-index: 1000;
  box-sizing: border-box;

  @media ${device.mobileS} {
    padding-left: 22px;
    padding-right: 22px;
  }
  @media ${device.mobileM} {
    padding-left: 22px;
    padding-right: 22px;
  }
  @media ${device.mobileL} {
    padding-left: 22px;
    padding-right: 22px;
  }
  @media ${device.tablet} {
    padding-left: 8%;
    padding-right: 8%;
  }
  @media ${device.laptop} {
    padding-left: 15%;
    padding-right: 15%;
  }
  @media ${device.laptopL} {
    padding-left: 15%;
    padding-right: 15%;
  }
  @media ${device.desktop} {
    padding-left: 15%;
    padding-right: 15%;
  }
  @media ${device.desktopL} {
    padding-left: 15%;
    padding-right: 15%;
  }
`;

class Content extends Component {
  render() {
    return (
      <ContentContainer>
        <Route exact={true} path="/" render={() => <Home></Home>} />
        <Route exact={true} path="/About" render={() => <About></About>} />
        <Route exact={true} path="/Work" render={() => <Work></Work>} />
        <Route
          exact={true}
          path="/Slumber"
          render={() => <Slumber></Slumber>}
        />
        <Route
          exact={true}
          path="/Appetizr"
          render={() => <Appetizr></Appetizr>}
        />
        <Route
          exact={true}
          path="/TalkyBlocks"
          render={() => <Talky></Talky>}
        />
      </ContentContainer>
    );
  }
}

export default Content;
