import React, { Component } from "react";
import MediaQuery from "react-responsive";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import Fade from "react-reveal/Fade";
import { device } from "../device.js";

import Resume from "../components/AdamCrosbyCV.pdf";

const DNav = styled.div`
  width: 100%;
  height: 44px;
  background-color: #0a0a0a;
  position: fixed;
  z-index: 99999;
  border-bottom-color: black;
  border-bottom-width: 1px;
  border-bottom-style: solid;
`;

const NavContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;

  @media ${device.tablet} {
    padding-left: 8%;
    padding-right: 8%;
  }
  @media ${device.laptop} {
    padding-left: 15%;
    padding-right: 15%;
  }
  @media ${device.laptopL} {
    padding-left: 15%;
    padding-right: 15%;
  }
  @media ${device.desktop} {
    padding-left: 15%;
    padding-right: 15%;
  }
  @media ${device.desktopL} {
    padding-left: 15%;
    padding-right: 15%;
  }
`;

const NavLeft = styled.div`
  color: white;
`;

const NavRight = styled.div`
  color: white;
`;

/*Mobile*/
const MNavigation = styled.div`
  width: 100%;
  position: fixed;
  z-index: 99999;
`;

const MobileBar = styled.div`
  width: 100%;
  height: 60px;
  position: fixed;
  z-index: 99999;
  background-color: #0a0a0a;
  box-sizing: border-box;
  padding: 0px 20px 0px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom-color: black;
  border-bottom-width: 1px;
  border-bottom-style: solid;
`;

const MNavLeft = styled.div``;
const MNavRight = styled.div``;

const MButton = styled.div`
  height: 60px;
  padding: 0px 20px 0px 20px;
  display: flex;
  align-items: center;
  color: white;
  font-size: 16px;
`;

const MHomeBtn = styled.p`
  color: white;
`;

const Menu = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #0d0d0d;
  z-index: 9900;
  position: fixed;
  display: flex;
  flex-direction: column;
  padding-top: 80px;
  padding-left: 22px;
  padding-right: 22px;
  padding-bottom: 22px;
  box-sizing: border-box;
  transition: ease-in-out 500ms;
  margin-top: -100vh;
`;

class MobileNav extends Component {
  state = { OpenMenu: false };

  open() {
    var element = document.getElementById("MobileMenu");
    element.classList.toggle("OpenMenu");
  }
  render() {
    return (
      <MNavigation>
        <MobileBar>
          <MNavLeft>
            <MButton onClick={this.open}>
              <i class="fas fa-bars"></i>
            </MButton>
          </MNavLeft>
          <MNavRight>
            <NavLink exact to="/" strict className="navLink">
              <MHomeBtn>
                CROSBY <span className="header animated">A</span>
              </MHomeBtn>
            </NavLink>
          </MNavRight>
        </MobileBar>
        <Menu id="MobileMenu">
          <NavLink
            exact
            to="/"
            strict
            className="mobileLink"
            activeClassName="activeMLink"
            onClick={this.open}
          >
            HOME
          </NavLink>
          <NavLink
            exact
            to="/Work"
            strict
            className="mobileLink"
            activeClassName="activeMLink"
            onClick={this.open}
          >
            PAST WORK
          </NavLink>
          <NavLink
            exact
            to="/About"
            strict
            className="mobileLink"
            activeClassName="activeMLink"
            onClick={this.open}
          >
            ABOUT
          </NavLink>
          <a
            href={Resume}
            target="_blank"
            className="mobileLink"
            activeClassName="activeMLink"
            onClick={this.open}
          >
            CV / RESUME
          </a>
        </Menu>
      </MNavigation>
    );
  }
}

class DesktopNav extends Component {
  render() {
    return (
      <DNav>
        <NavContent>
          <Fade>
            <NavLeft>
              <NavLink exact to="/" strict className="navLink">
                CROSBY <span className="header animated">A</span>
              </NavLink>
            </NavLeft>
          </Fade>
          <Fade>
            <NavRight>
              <NavLink
                exact
                to="/Work"
                strict
                className="navLink"
                activeClassName="activeNavLink animated"
              >
                PAST WORK
              </NavLink>
              <NavLink
                style={{ marginLeft: "32px" }}
                exact
                to="/About"
                strict
                className="navLink"
                activeClassName="activeNavLink animated"
              >
                ABOUT
              </NavLink>
            </NavRight>
          </Fade>
        </NavContent>
      </DNav>
    );
  }
}

function NavBar() {
  return (
    <div>
      <MediaQuery minDeviceWidth={768}>
        <DesktopNav></DesktopNav>
      </MediaQuery>
      <MediaQuery maxDeviceWidth={767}>
        <MobileNav></MobileNav>
      </MediaQuery>
    </div>
  );
}

export default NavBar;
