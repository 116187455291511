import React, { Component } from "react";
import { Parallax } from "react-scroll-parallax";
import MediaQuery from "react-responsive";
import styled from "styled-components";
import Fade from "react-reveal/Fade";
import { NavLink } from "react-router-dom";
import { device } from "../device.js";

import SmallWave from "../components/scrollwave";

import PreviewImg from "../images/Appetizr/AppetizrOne.png";
import Step from "../images/Appetizr/step.png";
import High from "../images/Appetizr/AppHigh.png";
import Spectrum from "../images/slumber/Spectrum.jpg";
import Story from "../images/slumber/story.jpg";
import Flow from "../images/slumber/flow.jpg";
import SMap from "../images/slumber/map.jpg";
import Wires from "../images/slumber/wires.png";

const SlumberContent = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  @media ${device.tablet} {
    padding-bottom: 80px;
  }
  @media ${device.laptop} {
    padding-bottom: 40px;
  }
  @media ${device.laptopL} {
    padding-bottom: 40px;
  }
  @media ${device.desktop} {
  }
  @media ${device.desktopL} {
  }
`;

const DesktopSlumber = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: absolute;
  left: 0;

  @media ${device.tablet} {
    padding-top: 8vh;
  }
  @media ${device.laptop} {
    padding-top: 0px;
  }
  @media ${device.laptopL} {
    padding-top: 0px;
  }
  @media ${device.desktop} {
  }
  @media ${device.desktopL} {
  }
`;

const MobileSlumber = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding-top: 10vh;
  align-items: center;
  overflow: scroll;
`;

const Title = styled.h1`
  color: white;
  margin: 0;
  padding: 0;
  letter-spacing: 1px;
  font-family: "Montserrat", sans-serif;

  @media ${device.mobileS} {
    font-size: 44px;
    line-height: 66px;
    margin-top: 10.5vh;
  }
  @media ${device.mobileM} {
    font-size: 44px;
    line-height: 66px;
    margin-top: 10.5vh;
  }
  @media ${device.mobileL} {
    font-size: 44px;
    line-height: 66px;
    margin-top: 10.5vh;
  }
  @media ${device.tablet} {
    font-size: 66px;
    line-height: 88px;
    margin-top: 10.5vh;
  }
  @media ${device.laptop} {
    font-size: 76px;
    line-height: 88px;
    margin-top: 17.5vh;
  }
  @media ${device.laptopL} {
    font-size: 76px;
    line-height: 108px;
    margin-top: 17.5vh;
  }
  @media ${device.desktop} {
    font-size: 88px;
    line-height: 88px;
    margin-top: 17.5vh;
  }
  @media ${device.desktopL} {
    font-size: 88px;
    line-height: 88px;
    margin-top: 17.5vh;
  }
`;

const ContentFrame = styled.div`
  width: 100%;
  min-height: fit-content;
  box-sizing: border-box;

  @media ${device.mobileS} {
    padding-left: 22px;
    padding-right: 22px;
  }
  @media ${device.mobileM} {
    padding-left: 22px;
    padding-right: 22px;
  }
  @media ${device.mobileL} {
    padding-left: 22px;
    padding-right: 22px;
  }
  @media ${device.tablet} {
    padding-left: 8%;
    padding-right: 8%;
  }
  @media ${device.laptop} {
    padding-left: 15%;
    padding-right: 15%;
  }
  @media ${device.laptopL} {
    padding-left: 15%;
    padding-right: 15%;
  }
  @media ${device.desktop} {
    padding-left: 15%;
    padding-right: 15%;
  }
  @media ${device.desktopL} {
    padding-left: 15%;
    padding-right: 15%;
  }
`;

const LightSubtitle = styled.h2`
  text-align: center;
  font-family: "Montserrat", sans-serif;
  color: white;

  @media ${device.mobileS} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    font-size: 16px;
    line-height: 30px;
    margin-top: 34px;
    margin-bottom: 36px;
  }
  @media ${device.mobileM} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    font-size: 16px;
    line-height: 30px;
    margin-top: 18px;
    margin-bottom: 36px;
  }
  @media ${device.mobileL} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    font-size: 16px;
    line-height: 30px;
    margin-top: 18px;
    margin-bottom: 36px;
  }
  @media ${device.tablet} {
    width: 100%;
    font-size: 22px;
    line-height: 48px;
    margin-top: 22px;
    margin-bottom: 0px;
  }
  @media ${device.laptop} {
    width: 100%;
    font-size: 22px;
    line-height: 48px;
    margin-top: 22px;
    margin-bottom: 0px;
  }
  @media ${device.laptopL} {
    width: 100%;
    font-size: 22px;
    line-height: 48px;
    margin-top: 22px;
    text-align: center;
    margin-bottom: 0px;
  }
  @media ${device.desktop} {
  }
  @media ${device.desktopL} {
  }
`;

const SubtitleTwo = styled.h3`
  text-align: left;
  font-family: "Montserrat", sans-serif;
  color: black;

  @media ${device.mobileS} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    font-size: 16px;
    line-height: 30px;
    margin-top: 34px;
  }
  @media ${device.mobileM} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    font-size: 16px;
    line-height: 30px;
    margin-top: 18px;
    margin-bottom: 0px;
  }
  @media ${device.mobileL} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    font-size: 16px;
    line-height: 30px;
    margin-top: 18px;
    margin-bottom: 0px;
  }
  @media ${device.tablet} {
    width: 70%;
    margin-left: 15%;
    margin-right: 15%;
    font-size: 22px;
    line-height: 48px;
    margin-top: 22px;
    margin-bottom: 0px;
  }
  @media ${device.laptop} {
    width: 65%;
    margin-left: 17.5%;
    margin-right: 17.5%;
    font-size: 22px;
    line-height: 52px;
    margin-top: 22px;
    margin-bottom: 22px;
  }
  @media ${device.laptopL} {
    width: 65%;
    margin-left: 17.5%;
    margin-right: 17.5%;
    font-size: 22px;
    line-height: 52px;
    margin-top: 22px;
    margin-bottom: 22px;
  }
  @media ${device.desktop} {
  }
  @media ${device.desktopL} {
  }
`;

const DarkSubtitle = styled.h2`
  text-align: left;
  font-family: "Montserrat", sans-serif;
  color: black;

  @media ${device.mobileS} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    font-size: 16px;
    line-height: 30px;
    margin-top: 34px;
  }
  @media ${device.mobileM} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    font-size: 16px;
    line-height: 30px;
    margin-top: 18px;
    margin-bottom: 0px;
  }
  @media ${device.mobileL} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    font-size: 16px;
    line-height: 30px;
    margin-top: 18px;
    margin-bottom: 0px;
  }
  @media ${device.tablet} {
    width: 70%;
    margin-left: 15%;
    margin-right: 15%;
    font-size: 22px;
    line-height: 48px;
    margin-top: 4px;
    margin-bottom: 0px;
  }
  @media ${device.laptop} {
    width: 65%;
    margin-left: 17.5%;
    margin-right: 17.5%;
    font-size: 26px;
    line-height: 52px;
    margin-top: 22px;
    margin-bottom: 22px;
  }
  @media ${device.laptopL} {
    width: 65%;
    margin-left: 17.5%;
    margin-right: 17.5%;
    font-size: 26px;
    line-height: 52px;
    margin-top: 22px;
    margin-bottom: 22px;
  }
  @media ${device.desktop} {
  }
  @media ${device.desktopL} {
  }
`;

const LSubtitle = styled.h2`
  text-align: left;
  font-family: "Montserrat", sans-serif;
  color: white;

  @media ${device.mobileS} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    font-size: 16px;
    line-height: 30px;
    margin-top: 34px;
  }
  @media ${device.mobileM} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    font-size: 16px;
    line-height: 30px;
    margin-top: 18px;
    margin-bottom: 0px;
  }
  @media ${device.mobileL} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    font-size: 16px;
    line-height: 30px;
    margin-top: 18px;
    margin-bottom: 0px;
  }
  @media ${device.tablet} {
    width: 70%;
    margin-left: 15%;
    margin-right: 15%;
    font-size: 22px;
    line-height: 48px;
    margin-top: 22px;
    margin-bottom: 0px;
  }
  @media ${device.laptop} {
    width: 65%;
    margin-left: 17.5%;
    margin-right: 17.5%;
    font-size: 26px;
    line-height: 52px;
    margin-top: 22px;
    margin-bottom: 22px;
  }
  @media ${device.laptopL} {
    width: 65%;
    margin-left: 17.5%;
    margin-right: 17.5%;
    font-size: 26px;
    line-height: 52px;
    margin-top: 22px;
    margin-bottom: 22px;
  }
  @media ${device.desktop} {
  }
  @media ${device.desktopL} {
  }
`;

const LightText = styled.p`
  font-family: "Lato", sans-serif;
  line-height: 38px;
  text-align: center;
  color: white;

  @media ${device.mobileS} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    font-size: 14px;
    line-height: 30px;
    text-align: left;
  }
  @media ${device.mobileM} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    font-size: 14px;
    line-height: 30px;
    text-align: left;
  }
  @media ${device.mobileL} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    font-size: 14px;
    line-height: 30px;
    text-align: left;
  }
  @media ${device.tablet} {
    width: 70%;
    margin-left: 15%;
    margin-right: 15%;
    font-size: 16px;
    line-height: 38px;
    text-align: center;
  }
  @media ${device.laptop} {
    width: 65%;
    margin-left: 17.5%;
    margin-right: 17.5%;
    font-size: 16px;
    line-height: 38px;
    text-align: center;
  }
  @media ${device.laptopL} {
    width: 65%;
    margin-left: 17.5%;
    margin-right: 17.5%;
    font-size: 16px;
    line-height: 38px;
    text-align: center;
  }
  @media ${device.desktop} {
  }
  @media ${device.desktopL} {
  }
`;

const Text = styled.p`
  font-family: "Lato", sans-serif;
  line-height: 38px;
  text-align: left;
  color: black;

  @media ${device.mobileS} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    font-size: 14px;
    line-height: 30px;
  }
  @media ${device.mobileM} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    font-size: 14px;
    line-height: 30px;
  }
  @media ${device.mobileL} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    font-size: 14px;
    line-height: 30px;
  }
  @media ${device.tablet} {
    width: 70%;
    margin-left: 15%;
    margin-right: 15%;
    font-size: 16px;
    line-height: 38px;
    margin-top: 16px;
  }
  @media ${device.laptop} {
    width: 65%;
    margin-left: 17.5%;
    margin-right: 17.5%;
    font-size: 16px;
    line-height: 38px;
  }
  @media ${device.laptopL} {
    width: 65%;
    margin-left: 17.5%;
    margin-right: 17.5%;
    font-size: 16px;
    line-height: 38px;
  }
  @media ${device.desktop} {
  }
  @media ${device.desktopL} {
  }
`;

const LText = styled.p`
  font-family: "Lato", sans-serif;
  line-height: 38px;
  text-align: left;
  color: white;

  @media ${device.mobileS} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    font-size: 14px;
    line-height: 30px;
  }
  @media ${device.mobileM} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    font-size: 14px;
    line-height: 30px;
  }
  @media ${device.mobileL} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    font-size: 14px;
    line-height: 30px;
  }
  @media ${device.tablet} {
    width: 70%;
    margin-left: 15%;
    margin-right: 15%;
    font-size: 16px;
    line-height: 38px;
  }
  @media ${device.laptop} {
    width: 65%;
    margin-left: 17.5%;
    margin-right: 17.5%;
    font-size: 16px;
    line-height: 38px;
  }
  @media ${device.laptopL} {
    width: 65%;
    margin-left: 17.5%;
    margin-right: 17.5%;
    font-size: 16px;
    line-height: 38px;
  }
  @media ${device.desktop} {
  }
  @media ${device.desktopL} {
  }
`;

const Preview = styled.div`
  width: 100%;
  height: 50vh;
  background-color: #0a0a0a;
  z-index: 50;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${device.mobileS} {
    height: 40vh;
  }
  @media ${device.mobileM} {
    height: 40vh;
  }
  @media ${device.mobileL} {
    height: 40vh;
  }
  @media ${device.tablet} {
    height: 50vh;
  }
  @media ${device.laptop} {
    height: 50vh;
  }
  @media ${device.laptopL} {
    height: 50vh;
  }
  @media ${device.desktop} {
  }
  @media ${device.desktopL} {
  }
`;

const PreviewImage = styled.img`
  height: 140%;
`;

const PreviewL = styled.div`
  width: 100%;
  height: fit-content;
  background-color: #0a0a0a;
  z-index: 50;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10vh;
  padding-bottom: 10vh;
`;

const Insights = styled.div`
  @media ${device.mobileS} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
  }
  @media ${device.mobileM} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
  }
  @media ${device.mobileL} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
  }
  @media ${device.tablet} {
    width: 70%;
    margin-left: 15%;
    margin-right: 15%;
    height: fit-content;
    display: grid;
    grid-column-gap: 22px;
    grid-template-columns: auto auto auto;
    margin-top: 0px;
  }
  @media ${device.laptop} {
    width: 65%;
    margin-left: 17.5%;
    margin-right: 17.5%;
    height: fit-content;
    display: grid;
    grid-column-gap: 22px;
    grid-template-columns: auto auto auto;
    margin-top: 0px;
  }
  @media ${device.laptopL} {
    width: 65%;
    margin-left: 17.5%;
    margin-right: 17.5%;
    height: fit-content;
    display: grid;
    grid-column-gap: 22px;
    grid-template-columns: auto auto auto;
    margin-top: 52px;
  }
  @media ${device.desktop} {
    height: fit-content;
    display: grid;
    grid-column-gap: 22px;
    grid-template-columns: auto auto auto;
    margin-top: 52px;
  }
  @media ${device.desktopL} {
    height: fit-content;
    display: grid;
    grid-column-gap: 22px;
    grid-template-columns: auto auto auto;
    margin-top: 52px;
  }
`;

const InsightItem = styled.div`
  @media ${device.mobileS} {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 44px;
    width: 85%;
    margin-left: 7.5%;
  }
  @media ${device.mobileM} {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 44px;
    width: 85%;
    margin-left: 7.5%;
  }
  @media ${device.mobileL} {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 44px;
    width: 85%;
    margin-left: 7.5%;
  }
  @media ${device.tablet} {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-left: 0%;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
  }
  @media ${device.laptop} {
    height: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-left: 0%;
  }
  @media ${device.laptopL} {
    height: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-left: 0%;
  }
  @media ${device.desktop} {
    height: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-left: 0%;
  }
  @media ${device.desktopL} {
    height: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-left: 0%;
  }
`;

const InsightImage = styled.div`
  @media ${device.mobileS} {
    width: 15vh;
    height: 0;
    padding-bottom: 15vh;
    background-position: center;
    background-size: cover;
    border-radius: 50%;
    border-style: solid;
    border-color: white;
    border-width: 6px;
    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.075);
    margin-bottom: 22px;
  }
  @media ${device.mobileM} {
    width: 15vh;
    height: 0;
    padding-bottom: 15vh;
    background-position: center;
    background-size: cover;
    border-radius: 50%;
    border-style: solid;
    border-color: white;
    border-width: 6px;
    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.075);
    margin-bottom: 22px;
  }
  @media ${device.mobileL} {
    width: 15vh;
    height: 0;
    padding-bottom: 15vh;
    background-position: center;
    background-size: cover;
    border-radius: 50%;
    border-style: solid;
    border-color: white;
    border-width: 6px;
    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.075);
    margin-bottom: 22px;
  }
  @media ${device.tablet} {
    width: 15vh;
    height: 0;
    padding-bottom: 15vh;
    background-position: center;
    background-size: cover;
    border-radius: 50%;
    border-style: solid;
    border-color: white;
    border-width: 6px;
    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.075);
    margin-bottom: 22px;
  }
  @media ${device.laptop} {
    width: 15vh;
    height: 0;
    padding-bottom: 15vh;
    background-position: center;
    background-size: cover;
    border-radius: 50%;
    border-style: solid;
    border-color: white;
    border-width: 6px;
    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.075);
    margin-bottom: 22px;
  }
  @media ${device.laptopL} {
    width: 15vh;
    height: 0;
    padding-bottom: 15vh;
    background-position: center;
    background-size: cover;
    border-radius: 50%;
    border-style: solid;
    border-color: white;
    border-width: 6px;
    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.075);
    margin-bottom: 22px;
  }
  @media ${device.desktop} {
    width: 15vh;
    height: 0;
    padding-bottom: 15vh;
    background-position: center;
    background-size: cover;
    border-radius: 50%;
    border-style: solid;
    border-color: white;
    border-width: 6px;
    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.075);
    margin-bottom: 22px;
  }
  @media ${device.desktopL} {
    width: 15vh;
    height: 0;
    padding-bottom: 15vh;
    background-position: center;
    background-size: cover;
    border-radius: 50%;
    border-style: solid;
    border-color: white;
    border-width: 6px;
    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.075);
    margin-bottom: 22px;
  }
`;

const InsightText = styled.p`
  @media ${device.mobileS} {
    font-size: 14px;
    line-height: 26px;
    text-align: center;
    font-weight: 700;
  }
  @media ${device.mobileM} {
    font-size: 14px;
    line-height: 26px;
    text-align: center;
    font-weight: 700;
  }
  @media ${device.mobileL} {
    font-size: 14px;
    line-height: 26px;
    text-align: center;
    font-weight: 700;
  }
  @media ${device.tablet} {
    font-size: 12px;
    line-height: 26px;
    text-align: center;
    font-weight: 700;
  }
  @media ${device.laptop} {
    font-size: 12px;
    line-height: 26px;
    text-align: center;
    font-weight: 700;
  }
  @media ${device.laptopL} {
    font-size: 12px;
    line-height: 26px;
    text-align: center;
    font-weight: 700;
  }
  @media ${device.desktop} {
    font-size: 12px;
    line-height: 26px;
    text-align: center;
    font-weight: 700;
  }
  @media ${device.desktopL} {
    font-size: 12px;
    line-height: 26px;
    text-align: center;
    font-weight: 700;
  }
`;

const Spacer = styled.div`
  @media ${device.mobileS} {
    width: 100%;
    height: 10vh;
  }
  @media ${device.mobileM} {
    width: 100%;
    height: 10vh;
  }
  @media ${device.mobileL} {
    width: 100%;
    height: 10vh;
  }
  @media ${device.tablet} {
    width: 100%;
    height: 20vh;
  }
  @media ${device.laptop} {
    width: 100%;
    height: 20vh;
  }
  @media ${device.laptopL} {
    width: 100%;
    height: 20vh;
  }
  @media ${device.desktop} {
    width: 100%;
    height: 20vh;
  }
  @media ${device.desktopL} {
    width: 100%;
    height: 20vh;
  }
`;

const ImageM = styled.img`
  margin-top: 22px;

  @media ${device.mobileS} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
  }
  @media ${device.mobileM} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
  }
  @media ${device.mobileL} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
  }
  @media ${device.tablet} {
    width: 70%;
    margin-left: 15%;
    margin-right: 15%;
  }
  @media ${device.laptop} {
    width: 65%;
    margin-left: 17.5%;
    margin-right: 17.5%;
  }
  @media ${device.laptopL} {
    width: 65%;
    margin-left: 17.5%;
    margin-right: 17.5%;
  }
  @media ${device.desktop} {
  }
  @media ${device.desktopL} {
  }
`;

const ReportFrame = styled.div`
  display: flex;
  flex-direction: row;
  @media ${device.mobileS} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    flex-direction: column;
    box-sizing: border-box;
    padding-right: 16px;
  }
  @media ${device.mobileM} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    flex-direction: column;
    box-sizing: border-box;
    padding-right: 16px;
  }
  @media ${device.mobileL} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    flex-direction: column;
    box-sizing: border-box;
    padding-right: 16px;
  }
  @media ${device.tablet} {
    width: 70%;
    margin-left: 15%;
    margin-right: 15%;
    flex-direction: row;
    box-sizing: border-box;
    padding-right: 0px;
  }
  @media ${device.laptop} {
    width: 65%;
    margin-left: 17.5%;
    margin-right: 17.5%;
    flex-direction: row;
    box-sizing: border-box;
    padding-right: 0px;
  }
  @media ${device.laptopL} {
    width: 65%;
    margin-left: 17.5%;
    margin-right: 17.5%;
    flex-direction: row;
    box-sizing: border-box;
    padding-right: 0px;
  }
  @media ${device.desktop} {
  }
  @media ${device.desktopL} {
  }
`;

const TileSub = styled.p`
  font-size: 16px;
  margin: 0;
  padding: 0;
  text-align: left;
  width: 100%;
  box-sizing: border-box;
  padding-left: 26px;
  padding-right: 12px;
  margin-top: 2px;
  font-family: "Lato", sans-serif;
  font-weight: 400;
`;

const Tile = styled.div`
  @media ${device.mobileS} {
    width: 100%;
    margin-top: 22px;
    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.075);
    cursor: pointer;

    :hover {
      box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.035);
    }
  }
  @media ${device.mobileM} {
    width: 100%;
    margin-top: 22px;
    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.075);
    cursor: pointer;

    :hover {
      box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.035);
    }
  }
  @media ${device.mobileL} {
    width: 100%;
    margin-top: 22px;
    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.075);
    cursor: pointer;

    :hover {
      box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.035);
    }
  }
  @media ${device.tablet} {
    width: fit-content;
    margin-top: 22px;
    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.075);
    cursor: pointer;

    :hover {
      box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.035);
    }
  }
  @media ${device.laptop} {
    width: fit-content;
    margin-top: 22px;
    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.075);
    cursor: pointer;

    :hover {
      box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.035);
    }
  }
  @media ${device.laptopL} {
    width: fit-content;
    margin-top: 22px;
    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.075);
    cursor: pointer;

    :hover {
      box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.035);
    }
  }
  @media ${device.desktop} {
    width: fit-content;
    margin-top: 22px;
    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.075);
    cursor: pointer;

    :hover {
      box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.035);
    }
  }
  @media ${device.desktopL} {
    width: fit-content;
    margin-top: 22px;
    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.075);
    cursor: pointer;

    :hover {
      box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.035);
    }
  }
`;

const TileContentS = styled.div`
  @media ${device.mobileS} {
    width: 100%;
    text-align: center;
    padding: 16px 22px 18px 0px;
    background-color: #f7f7f7;
    color: black;
    transition: ease-in-out 250ms;
    font-size: 12px;
    border-radius: 8px;
  }
  @media ${device.mobileM} {
    width: 100%;
    text-align: center;
    padding: 16px 22px 18px 0px;
    background-color: #f7f7f7;
    color: black;
    transition: ease-in-out 250ms;
    font-size: 12px;
    border-radius: 8px;
  }
  @media ${device.mobileL} {
    width: 100%;
    text-align: center;
    padding: 16px 22px 18px 0px;
    background-color: #f7f7f7;
    color: black;
    transition: ease-in-out 250ms;
    font-size: 12px;
    border-radius: 8px;
  }
  @media ${device.tablet} {
    width: 100%;
    text-align: center;
    padding: 16px 22px 18px 0px;
    background-color: #f7f7f7;
    color: black;
    transition: ease-in-out 250ms;
    font-size: 12px;
    border-radius: 8px;
  }
  @media ${device.laptop} {
    width: 100%;
    text-align: center;
    padding: 16px 22px 18px 0px;
    background-color: #f7f7f7;
    color: black;
    transition: ease-in-out 250ms;
    font-size: 12px;
    border-radius: 8px;
  }
  @media ${device.laptopL} {
    width: 100%;
    text-align: center;
    padding: 16px 22px 18px 0px;
    background-color: #f7f7f7;
    color: black;
    transition: ease-in-out 250ms;
    font-size: 12px;
    border-radius: 8px;
  }
  @media ${device.desktop} {
    width: 100%;
    text-align: center;
    padding: 16px 22px 18px 0px;
    background-color: #f7f7f7;
    color: black;
    transition: ease-in-out 250ms;
    font-size: 12px;
    border-radius: 8px;
  }
  @media ${device.desktopL} {
    width: 100%;
    text-align: center;
    padding: 16px 22px 18px 0px;
    background-color: #f7f7f7;
    color: black;
    transition: ease-in-out 250ms;
    font-size: 12px;
    border-radius: 8px;
  }
`;

const TilebtnM = styled.div`
  border-radius: 16px;
  border-style: solid;
  border-width: 6px;
  border-color: white !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
`;

const Intro = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  opacity: 1;
  @media ${device.mobileS} {
    padding-top: 10vh;
  }
  @media ${device.mobileM} {
    padding-top: 10vh;
  }
  @media ${device.mobileL} {
    padding-top: 10vh;
  }
  @media ${device.tablet} {
    padding-top: 0vh;
  }
  @media ${device.laptop} {
    padding-top: 0vh;
  }
  @media ${device.laptopL} {
    padding-top: 0vh;
  }
  @media ${device.desktop} {
    padding-top: 0vh;
  }
  @media ${device.desktopL} {
    padding-top: 0vh;
  }
`;

const ContactLink = styled.a`
  margin-left: 8px;
  background: linear-gradient(to right, #5c0084, #d83d99);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: ease-in-out 250ms;

  :hover {
    opacity: 0.5;
  }
`;

class Appetizr extends Component {
  componentDidMount() {
    var height = document.getElementById("white");
    height.classList.add("PHeight");
    height.classList.remove("HHeight");
    height.classList.remove("AHeight");
    height.classList.remove("WHeight");
  }
  render() {
    return (
      <SlumberContent>
        <DesktopSlumber>
          <div className="CaseContain">
            <SmallWave></SmallWave>
            <div className="Process">
              <ContentFrame style={{ paddingTop: "5vh" }}>
                <Fade bottom delay="250" distance="20px">
                  <SubtitleTwo className="header animated">
                    The Challenge
                  </SubtitleTwo>
                </Fade>
                <Fade bottom delay="250" distance="20px">
                  <DarkSubtitle>
                    Design & develop a solution that allows easy sharing and
                    discovery of recipes.
                  </DarkSubtitle>
                </Fade>
                <Fade bottom delay="250" distance="20px">
                  <Text>
                    Recently, I encountered an interesting problem. My partner,
                    who loves to cook, would store and find recipes across
                    multiple mediums such as notebooks, Google docs, and
                    Pinterest Boards. Although this would work, it also led to
                    inconsistent formats and the loss of our favorite recipes
                    due to disorganization.It was this challenge that I would
                    seek to solve by creating a user-friendly recipe sharing
                    application.
                  </Text>
                </Fade>
              </ContentFrame>
            </div>
            <Preview className="shadow">
              <PreviewImage src={PreviewImg}></PreviewImage>
            </Preview>
            <div className="Process" style={{ paddingTop: "20vh" }}>
              <ContentFrame>
                <Fade bottom delay="250" distance="20px">
                  <SubtitleTwo className="header animated">
                    Pain Points
                  </SubtitleTwo>
                </Fade>
                <Fade bottom delay="250" distance="20px">
                  <DarkSubtitle>Why is this a problem?</DarkSubtitle>
                </Fade>
                <Fade bottom delay="250" distance="20px">
                  <Text>
                    To gather further insight, I surveyed multiple users to
                    validate my assumptions regarding the typical recipe
                    experience. Resulting in the following key points:
                  </Text>
                </Fade>
                <Insights>
                  <Fade bottom delay="250" distance="20px">
                    <InsightItem>
                      <InsightImage className="InsightOneA"></InsightImage>
                      <InsightText>
                        People want a simple and minimalist way to save recipes.
                      </InsightText>
                    </InsightItem>
                  </Fade>
                  <Fade bottom delay="350" distance="20px">
                    <InsightItem>
                      <InsightImage className="InsightTwoA"></InsightImage>
                      <InsightText>
                        People often share recipes with family & friends.
                      </InsightText>
                    </InsightItem>
                  </Fade>
                  <Fade bottom delay="450" distance="20px">
                    <InsightItem>
                      <InsightImage className="InsightThreeA"></InsightImage>
                      <InsightText>
                        People want a recipe application that has little to no
                        advertisements.
                      </InsightText>
                    </InsightItem>
                  </Fade>
                </Insights>
                <Spacer></Spacer>
                <Fade bottom delay="250" distance="20px">
                  <SubtitleTwo className="header animated">
                    User Journey
                  </SubtitleTwo>
                </Fade>
                <Fade bottom delay="250" distance="20px">
                  <DarkSubtitle>Preparing to cook</DarkSubtitle>
                </Fade>
                <Fade bottom delay="250" distance="20px">
                  <Text>
                    Before creating wireframes, I started by visualizing the
                    user journey, providing a clear view of the application's
                    overall experience, and exploring the primary user flow.
                  </Text>
                </Fade>
                <Fade bottom delay="250" distance="20px">
                  <ImageM src={Step}></ImageM>
                </Fade>
                <Spacer></Spacer>
              </ContentFrame>
            </div>
            <PreviewL className="AppWires shadow">
              <ContentFrame>
                <Fade bottom delay="250" distance="20px">
                  <SubtitleTwo
                    className="header animated"
                    style={{ textAlign: "center" }}
                  >
                    Early Wireframing
                  </SubtitleTwo>
                </Fade>
                <Spacer></Spacer>
                <Spacer></Spacer>
                <Spacer></Spacer>
              </ContentFrame>
            </PreviewL>
            <div
              className="Process"
              style={{ paddingTop: "15vh", paddingBottom: "20vh" }}
            >
              <ContentFrame>
                <Fade bottom delay="250" distance="20px">
                  <SubtitleTwo className="header animated">
                    User Testing
                  </SubtitleTwo>
                </Fade>
                <Fade bottom delay="250" distance="20px">
                  <DarkSubtitle>Gathering feedback</DarkSubtitle>
                </Fade>
                <Fade bottom delay="250" distance="20px">
                  <Text>
                    While presenting my early designs to potential users, I
                    collected feedback regarding the placement of information,
                    application features, and overall design layout. I would
                    then use this feedback to design a more accurate high
                    fidelity prototype that achieves the application's goal.
                  </Text>
                </Fade>
                <Spacer></Spacer>
                <Fade bottom delay="250" distance="20px">
                  <SubtitleTwo className="header animated">
                    High-fidelity Mockups
                  </SubtitleTwo>
                </Fade>
                <Fade bottom delay="250" distance="20px">
                  <DarkSubtitle>Creating the application design</DarkSubtitle>
                </Fade>
                <Fade bottom delay="250" distance="20px">
                  <ImageM src={High}></ImageM>
                </Fade>
                <Spacer></Spacer>
                <Fade bottom delay="250" distance="20px">
                  <SubtitleTwo className="header animated">
                    What’s next
                  </SubtitleTwo>
                </Fade>
                <Fade bottom delay="250" distance="20px">
                  <DarkSubtitle>Developing the beta</DarkSubtitle>
                </Fade>
                <Fade bottom delay="250" distance="20px">
                  <Text style={{ marginBottom: "10vh" }}>
                    In early development, I am currently working with Colton
                    Toscher (Developer) on a swiftUI beta for conducting early
                    usability testing. Once complete, we will be implementing
                    further design updates and exploring additional
                    functionality to improve the overall application before a
                    late 2021 app store launch. <br></br>
                    <br></br>
                    Interested in discussing further?{" "}
                    <ContactLink href="mailto:crosbyadesign@gmail.com">
                      Let’s Talk
                    </ContactLink>
                  </Text>
                </Fade>
              </ContentFrame>
            </div>
          </div>
          <div className="CaseIntro">
            <ContentFrame>
              <Intro id="intro">
                <Fade bottom delay="450" distance="20px">
                  <Title className="header animated">Appetizr</Title>
                </Fade>
                <Fade bottom delay="500" distance="20px">
                  <LightSubtitle>
                    Re-defining the recipe sharing experience.
                  </LightSubtitle>
                </Fade>
                <Fade bottom delay="500" distance="20px">
                  <LightText>
                    Developed as a personal project, Appetizr is a social
                    media-based mobile IOS application designed to improve the
                    recipe sharing experience. Achieving this by providing a
                    simple, consistent, and minimalist experience to promote
                    sharing and discovery of new recipes.
                  </LightText>
                </Fade>
              </Intro>
            </ContentFrame>
          </div>
        </DesktopSlumber>
      </SlumberContent>
    );
  }
}

export default Appetizr;
