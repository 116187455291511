import React, { Component } from "react";
import { render } from "react-dom";
import { Frame, Page } from "framer";

class Swiper extends Component {
  render() {
    return (
      <Page width={"50vw"} height={"50%"} radius={0}>
        <Frame size={100} radius={0} background={"red"} />
        <Frame size={100} radius={0} background={"red"} />
        <Frame size={100} radius={0} background={"red"} />
      </Page>
    );
  }
}

export default Swiper;
