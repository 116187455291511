import React, { Component } from "react";
import MediaQuery from "react-responsive";
import styled from "styled-components";
import Fade from "react-reveal/Fade";
import { NavLink } from "react-router-dom";
import { device } from "../device.js";

import PhoneBG from "../images/Iphone.png";
import PhoneEdge from "../images/phoneFrame.png";



const PhoneFrame = styled.div`
  height: 75%;
  position: absolute;
  z-index: 2;
  display: flex;
  justify-content: center;
`;

const PhoneImage = styled.img`
  height: 100%;
  position: sticky;
  z-index: 2;
`;

const PhoneEdges = styled.img`
  height: 100%;
  position: absolute;
  z-index: 5;
`;

const PhoneGradient = styled.div`
  height: 96%;
  width: 91%;
  position: absolute;
  z-index: 3;
  margin-top: 4%;
  border-radius: 4vh;
  opacity: 0.65;
  box-shadow: 10px 10px 40px 10px rgba(0, 0, 0, 1);
`;

const PhoneContent = styled.div`
  height: 98%;
  width: 91%;
  position: absolute;
  z-index: 4;
  margin-top: 2%;
  border-radius: 4vh;
  overflow: hidden;
`;

class WbPhone extends Component {
  render() {
    return (
      <PhoneFrame>
        <PhoneImage src={PhoneBG}></PhoneImage>
        <PhoneGradient className="Gcolor animated"></PhoneGradient>
        <PhoneContent id="PhoneScreen"></PhoneContent>
        <PhoneEdges src={PhoneEdge}></PhoneEdges>
      </PhoneFrame>
    );
  }
}

export default WbPhone;
