import React, { Component } from "react";
import { Parallax } from "react-scroll-parallax";
import MediaQuery from "react-responsive";
import styled from "styled-components";
import Fade from "react-reveal/Fade";
import { NavLink } from "react-router-dom";
import { device } from "../device.js";

import SmallWave from "../components/scrollwave";

import Step from "../images/Talky/shape.png";
import Flow from "../images/Talky/flowc.png";
import Mvp from "../images/Talky/mvp.png";
import Car from "../images/Talky/car.png";
import Wires from "../images/Talky/wireT.png";

const SlumberContent = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  @media ${device.tablet} {
    padding-bottom: 80px;
  }
  @media ${device.laptop} {
    padding-bottom: 40px;
  }
  @media ${device.laptopL} {
    padding-bottom: 40px;
  }
  @media ${device.desktop} {
  }
  @media ${device.desktopL} {
  }
`;

const DesktopSlumber = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: absolute;
  left: 0;

  @media ${device.tablet} {
    padding-top: 8vh;
  }
  @media ${device.laptop} {
    padding-top: 0px;
  }
  @media ${device.laptopL} {
    padding-top: 0px;
  }
  @media ${device.desktop} {
  }
  @media ${device.desktopL} {
  }
`;

const MobileSlumber = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding-top: 10vh;
  align-items: center;
  overflow: scroll;
`;

const Title = styled.h1`
  color: white;
  margin: 0;
  padding: 0;
  letter-spacing: 1px;
  font-family: "Montserrat", sans-serif;

  @media ${device.mobileS} {
    font-size: 44px;
    line-height: 66px;
    margin-top: 10.5vh;
  }
  @media ${device.mobileM} {
    font-size: 44px;
    line-height: 66px;
    margin-top: 10.5vh;
  }
  @media ${device.mobileL} {
    font-size: 44px;
    line-height: 66px;
    margin-top: 10.5vh;
  }
  @media ${device.tablet} {
    font-size: 66px;
    line-height: 88px;
    margin-top: 10.5vh;
  }
  @media ${device.laptop} {
    font-size: 76px;
    line-height: 88px;
    margin-top: 17.5vh;
  }
  @media ${device.laptopL} {
    font-size: 76px;
    line-height: 108px;
    margin-top: 17.5vh;
  }
  @media ${device.desktop} {
    font-size: 88px;
    line-height: 88px;
    margin-top: 17.5vh;
  }
  @media ${device.desktopL} {
    font-size: 88px;
    line-height: 88px;
    margin-top: 17.5vh;
  }
`;

const ContentFrame = styled.div`
  width: 100%;
  min-height: fit-content;
  box-sizing: border-box;

  @media ${device.mobileS} {
    padding-left: 22px;
    padding-right: 22px;
  }
  @media ${device.mobileM} {
    padding-left: 22px;
    padding-right: 22px;
  }
  @media ${device.mobileL} {
    padding-left: 22px;
    padding-right: 22px;
  }
  @media ${device.tablet} {
    padding-left: 8%;
    padding-right: 8%;
  }
  @media ${device.laptop} {
    padding-left: 15%;
    padding-right: 15%;
  }
  @media ${device.laptopL} {
    padding-left: 15%;
    padding-right: 15%;
  }
  @media ${device.desktop} {
    padding-left: 15%;
    padding-right: 15%;
  }
  @media ${device.desktopL} {
    padding-left: 15%;
    padding-right: 15%;
  }
`;

const LightSubtitle = styled.h2`
  text-align: center;
  font-family: "Montserrat", sans-serif;
  color: white;

  @media ${device.mobileS} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    font-size: 16px;
    line-height: 30px;
    margin-top: 34px;
    margin-bottom: 36px;
  }
  @media ${device.mobileM} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    font-size: 16px;
    line-height: 30px;
    margin-top: 18px;
    margin-bottom: 36px;
  }
  @media ${device.mobileL} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    font-size: 16px;
    line-height: 30px;
    margin-top: 18px;
    margin-bottom: 36px;
  }
  @media ${device.tablet} {
    width: 100%;
    font-size: 22px;
    line-height: 48px;
    margin-top: 22px;
    margin-bottom: 0px;
  }
  @media ${device.laptop} {
    width: 100%;
    font-size: 22px;
    line-height: 48px;
    margin-top: 22px;
    margin-bottom: 0px;
  }
  @media ${device.laptopL} {
    width: 100%;
    font-size: 22px;
    line-height: 48px;
    margin-top: 22px;
    text-align: center;
    margin-bottom: 0px;
  }
  @media ${device.desktop} {
  }
  @media ${device.desktopL} {
  }
`;

const SubtitleTwo = styled.h3`
  text-align: left;
  font-family: "Montserrat", sans-serif;
  color: black;

  @media ${device.mobileS} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    font-size: 16px;
    line-height: 30px;
    margin-top: 34px;
  }
  @media ${device.mobileM} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    font-size: 16px;
    line-height: 30px;
    margin-top: 18px;
    margin-bottom: 0px;
  }
  @media ${device.mobileL} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    font-size: 16px;
    line-height: 30px;
    margin-top: 18px;
    margin-bottom: 0px;
  }
  @media ${device.tablet} {
    width: 70%;
    margin-left: 15%;
    margin-right: 15%;
    font-size: 22px;
    line-height: 48px;
    margin-top: 22px;
    margin-bottom: 0px;
  }
  @media ${device.laptop} {
    width: 65%;
    margin-left: 17.5%;
    margin-right: 17.5%;
    font-size: 22px;
    line-height: 52px;
    margin-top: 22px;
    margin-bottom: 0px;
  }
  @media ${device.laptopL} {
    width: 65%;
    margin-left: 17.5%;
    margin-right: 17.5%;
    font-size: 22px;
    line-height: 52px;
    margin-top: 22px;
    margin-bottom: 0px;
  }
  @media ${device.desktop} {
  }
  @media ${device.desktopL} {
  }
`;

const DarkSubtitle = styled.h2`
  text-align: left;
  font-family: "Montserrat", sans-serif;
  color: black;

  @media ${device.mobileS} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    font-size: 16px;
    line-height: 30px;
    margin-top: 34px;
  }
  @media ${device.mobileM} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    font-size: 16px;
    line-height: 30px;
    margin-top: 18px;
    margin-bottom: 0px;
  }
  @media ${device.mobileL} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    font-size: 16px;
    line-height: 30px;
    margin-top: 18px;
    margin-bottom: 0px;
  }
  @media ${device.tablet} {
    width: 70%;
    margin-left: 15%;
    margin-right: 15%;
    font-size: 22px;
    line-height: 48px;
    margin-top: 4px;
    margin-bottom: 0px;
  }
  @media ${device.laptop} {
    width: 65%;
    margin-left: 17.5%;
    margin-right: 17.5%;
    font-size: 26px;
    line-height: 52px;
    margin-top: 22px;
    margin-bottom: 22px;
  }
  @media ${device.laptopL} {
    width: 65%;
    margin-left: 17.5%;
    margin-right: 17.5%;
    font-size: 26px;
    line-height: 52px;
    margin-top: 22px;
    margin-bottom: 22px;
  }
  @media ${device.desktop} {
  }
  @media ${device.desktopL} {
  }
`;

const LSubtitle = styled.h2`
  text-align: left;
  font-family: "Montserrat", sans-serif;
  color: white;

  @media ${device.mobileS} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    font-size: 16px;
    line-height: 30px;
    margin-top: 34px;
  }
  @media ${device.mobileM} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    font-size: 16px;
    line-height: 30px;
    margin-top: 18px;
    margin-bottom: 0px;
  }
  @media ${device.mobileL} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    font-size: 16px;
    line-height: 30px;
    margin-top: 18px;
    margin-bottom: 0px;
  }
  @media ${device.tablet} {
    width: 70%;
    margin-left: 15%;
    margin-right: 15%;
    font-size: 22px;
    line-height: 48px;
    margin-top: 22px;
    margin-bottom: 0px;
  }
  @media ${device.laptop} {
    width: 65%;
    margin-left: 17.5%;
    margin-right: 17.5%;
    font-size: 26px;
    line-height: 52px;
    margin-top: 22px;
    margin-bottom: 22px;
  }
  @media ${device.laptopL} {
    width: 65%;
    margin-left: 17.5%;
    margin-right: 17.5%;
    font-size: 26px;
    line-height: 52px;
    margin-top: 22px;
    margin-bottom: 22px;
  }
  @media ${device.desktop} {
  }
  @media ${device.desktopL} {
  }
`;

const LightText = styled.p`
  font-family: "Lato", sans-serif;
  line-height: 38px;
  text-align: center;
  color: white;

  @media ${device.mobileS} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    font-size: 14px;
    line-height: 30px;
    text-align: left;
  }
  @media ${device.mobileM} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    font-size: 14px;
    line-height: 30px;
    text-align: left;
  }
  @media ${device.mobileL} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    font-size: 14px;
    line-height: 30px;
    text-align: left;
  }
  @media ${device.tablet} {
    width: 70%;
    margin-left: 15%;
    margin-right: 15%;
    font-size: 16px;
    line-height: 38px;
    text-align: center;
  }
  @media ${device.laptop} {
    width: 65%;
    margin-left: 17.5%;
    margin-right: 17.5%;
    font-size: 16px;
    line-height: 38px;
    text-align: center;
  }
  @media ${device.laptopL} {
    width: 65%;
    margin-left: 17.5%;
    margin-right: 17.5%;
    font-size: 16px;
    line-height: 38px;
    text-align: center;
  }
  @media ${device.desktop} {
  }
  @media ${device.desktopL} {
  }
`;

const Text = styled.p`
  font-family: "Lato", sans-serif;
  line-height: 38px;
  text-align: left;
  color: black;

  @media ${device.mobileS} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    font-size: 14px;
    line-height: 30px;
  }
  @media ${device.mobileM} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    font-size: 14px;
    line-height: 30px;
  }
  @media ${device.mobileL} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    font-size: 14px;
    line-height: 30px;
  }
  @media ${device.tablet} {
    width: 70%;
    margin-left: 15%;
    margin-right: 15%;
    font-size: 16px;
    line-height: 38px;
    margin-top: 16px;
  }
  @media ${device.laptop} {
    width: 65%;
    margin-left: 17.5%;
    margin-right: 17.5%;
    font-size: 16px;
    line-height: 38px;
  }
  @media ${device.laptopL} {
    width: 65%;
    margin-left: 17.5%;
    margin-right: 17.5%;
    font-size: 16px;
    line-height: 38px;
  }
  @media ${device.desktop} {
  }
  @media ${device.desktopL} {
  }
`;

const LText = styled.p`
  font-family: "Lato", sans-serif;
  line-height: 38px;
  text-align: left;
  color: white;

  @media ${device.mobileS} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    font-size: 14px;
    line-height: 30px;
  }
  @media ${device.mobileM} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    font-size: 14px;
    line-height: 30px;
  }
  @media ${device.mobileL} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    font-size: 14px;
    line-height: 30px;
  }
  @media ${device.tablet} {
    width: 70%;
    margin-left: 15%;
    margin-right: 15%;
    font-size: 16px;
    line-height: 38px;
  }
  @media ${device.laptop} {
    width: 65%;
    margin-left: 17.5%;
    margin-right: 17.5%;
    font-size: 16px;
    line-height: 38px;
  }
  @media ${device.laptopL} {
    width: 65%;
    margin-left: 17.5%;
    margin-right: 17.5%;
    font-size: 16px;
    line-height: 38px;
  }
  @media ${device.desktop} {
  }
  @media ${device.desktopL} {
  }
`;

const Preview = styled.div`
  width: 100%;
  height: 50vh;
  background-color: #0a0a0a;
  z-index: 50;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${device.mobileS} {
    height: 40vh;
  }
  @media ${device.mobileM} {
    height: 40vh;
  }
  @media ${device.mobileL} {
    height: 40vh;
  }
  @media ${device.tablet} {
    height: 50vh;
  }
  @media ${device.laptop} {
    height: 50vh;
  }
  @media ${device.laptopL} {
    height: 50vh;
  }
  @media ${device.desktop} {
  }
  @media ${device.desktopL} {
  }
`;

const PreviewImage = styled.img`
  height: 140%;
`;

const PreviewL = styled.div`
  width: 100%;
  height: fit-content;
  background-color: #0a0a0a;
  z-index: 50;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10vh;
  padding-bottom: 10vh;
`;

const Insights = styled.div`
  @media ${device.mobileS} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
  }
  @media ${device.mobileM} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
  }
  @media ${device.mobileL} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
  }
  @media ${device.tablet} {
    width: 70%;
    margin-left: 15%;
    margin-right: 15%;
    height: fit-content;
    display: grid;
    grid-column-gap: 22px;
    grid-template-columns: auto auto auto;
    margin-top: 0px;
  }
  @media ${device.laptop} {
    width: 65%;
    margin-left: 17.5%;
    margin-right: 17.5%;
    height: fit-content;
    display: grid;
    grid-column-gap: 22px;
    grid-template-columns: auto auto auto;
    margin-top: 0px;
  }
  @media ${device.laptopL} {
    width: 65%;
    margin-left: 17.5%;
    margin-right: 17.5%;
    height: fit-content;
    display: grid;
    grid-column-gap: 22px;
    grid-template-columns: auto auto auto;
    margin-top: 52px;
  }
  @media ${device.desktop} {
    height: fit-content;
    display: grid;
    grid-column-gap: 22px;
    grid-template-columns: auto auto auto;
    margin-top: 52px;
  }
  @media ${device.desktopL} {
    height: fit-content;
    display: grid;
    grid-column-gap: 22px;
    grid-template-columns: auto auto auto;
    margin-top: 52px;
  }
`;

const InsightItem = styled.div`
  @media ${device.mobileS} {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 44px;
    width: 85%;
    margin-left: 7.5%;
  }
  @media ${device.mobileM} {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 44px;
    width: 85%;
    margin-left: 7.5%;
  }
  @media ${device.mobileL} {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 44px;
    width: 85%;
    margin-left: 7.5%;
  }
  @media ${device.tablet} {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-left: 0%;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
  }
  @media ${device.laptop} {
    height: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-left: 0%;
  }
  @media ${device.laptopL} {
    height: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-left: 0%;
  }
  @media ${device.desktop} {
    height: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-left: 0%;
  }
  @media ${device.desktopL} {
    height: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-left: 0%;
  }
`;

const InsightImage = styled.div`
  @media ${device.mobileS} {
    width: 15vh;
    height: 0;
    padding-bottom: 15vh;
    background-position: center;
    background-size: cover;
    border-radius: 50%;
    border-style: solid;
    border-color: white;
    border-width: 6px;
    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.075);
    margin-bottom: 22px;
  }
  @media ${device.mobileM} {
    width: 15vh;
    height: 0;
    padding-bottom: 15vh;
    background-position: center;
    background-size: cover;
    border-radius: 50%;
    border-style: solid;
    border-color: white;
    border-width: 6px;
    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.075);
    margin-bottom: 22px;
  }
  @media ${device.mobileL} {
    width: 15vh;
    height: 0;
    padding-bottom: 15vh;
    background-position: center;
    background-size: cover;
    border-radius: 50%;
    border-style: solid;
    border-color: white;
    border-width: 6px;
    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.075);
    margin-bottom: 22px;
  }
  @media ${device.tablet} {
    width: 15vh;
    height: 0;
    padding-bottom: 15vh;
    background-position: center;
    background-size: cover;
    border-radius: 50%;
    border-style: solid;
    border-color: white;
    border-width: 6px;
    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.075);
    margin-bottom: 22px;
  }
  @media ${device.laptop} {
    width: 15vh;
    height: 0;
    padding-bottom: 15vh;
    background-position: center;
    background-size: cover;
    border-radius: 50%;
    border-style: solid;
    border-color: white;
    border-width: 6px;
    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.075);
    margin-bottom: 22px;
  }
  @media ${device.laptopL} {
    width: 15vh;
    height: 0;
    padding-bottom: 15vh;
    background-position: center;
    background-size: cover;
    border-radius: 50%;
    border-style: solid;
    border-color: white;
    border-width: 6px;
    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.075);
    margin-bottom: 22px;
  }
  @media ${device.desktop} {
    width: 15vh;
    height: 0;
    padding-bottom: 15vh;
    background-position: center;
    background-size: cover;
    border-radius: 50%;
    border-style: solid;
    border-color: white;
    border-width: 6px;
    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.075);
    margin-bottom: 22px;
  }
  @media ${device.desktopL} {
    width: 15vh;
    height: 0;
    padding-bottom: 15vh;
    background-position: center;
    background-size: cover;
    border-radius: 50%;
    border-style: solid;
    border-color: white;
    border-width: 6px;
    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.075);
    margin-bottom: 22px;
  }
`;

const InsightText = styled.p`
  @media ${device.mobileS} {
    font-size: 14px;
    line-height: 26px;
    text-align: center;
    font-weight: 700;
  }
  @media ${device.mobileM} {
    font-size: 14px;
    line-height: 26px;
    text-align: center;
    font-weight: 700;
  }
  @media ${device.mobileL} {
    font-size: 14px;
    line-height: 26px;
    text-align: center;
    font-weight: 700;
  }
  @media ${device.tablet} {
    font-size: 12px;
    line-height: 26px;
    text-align: center;
    font-weight: 700;
  }
  @media ${device.laptop} {
    font-size: 12px;
    line-height: 26px;
    text-align: center;
    font-weight: 700;
  }
  @media ${device.laptopL} {
    font-size: 12px;
    line-height: 26px;
    text-align: center;
    font-weight: 700;
  }
  @media ${device.desktop} {
    font-size: 12px;
    line-height: 26px;
    text-align: center;
    font-weight: 700;
  }
  @media ${device.desktopL} {
    font-size: 12px;
    line-height: 26px;
    text-align: center;
    font-weight: 700;
  }
`;

const Spacer = styled.div`
  @media ${device.mobileS} {
    width: 100%;
    height: 10vh;
  }
  @media ${device.mobileM} {
    width: 100%;
    height: 10vh;
  }
  @media ${device.mobileL} {
    width: 100%;
    height: 10vh;
  }
  @media ${device.tablet} {
    width: 100%;
    height: 20vh;
  }
  @media ${device.laptop} {
    width: 100%;
    height: 20vh;
  }
  @media ${device.laptopL} {
    width: 100%;
    height: 20vh;
  }
  @media ${device.desktop} {
    width: 100%;
    height: 20vh;
  }
  @media ${device.desktopL} {
    width: 100%;
    height: 20vh;
  }
`;

const ImageM = styled.img`
  margin-top: 22px;

  @media ${device.mobileS} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
  }
  @media ${device.mobileM} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
  }
  @media ${device.mobileL} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
  }
  @media ${device.tablet} {
    width: 70%;
    margin-left: 15%;
    margin-right: 15%;
  }
  @media ${device.laptop} {
    width: 65%;
    margin-left: 17.5%;
    margin-right: 17.5%;
  }
  @media ${device.laptopL} {
    width: 65%;
    margin-left: 17.5%;
    margin-right: 17.5%;
  }
  @media ${device.desktop} {
  }
  @media ${device.desktopL} {
  }
`;

const ReportFrame = styled.div`
  display: flex;
  flex-direction: row;
  @media ${device.mobileS} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    flex-direction: column;
    box-sizing: border-box;
    padding-right: 16px;
  }
  @media ${device.mobileM} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    flex-direction: column;
    box-sizing: border-box;
    padding-right: 16px;
  }
  @media ${device.mobileL} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    flex-direction: column;
    box-sizing: border-box;
    padding-right: 16px;
  }
  @media ${device.tablet} {
    width: 70%;
    margin-left: 15%;
    margin-right: 15%;
    flex-direction: row;
    box-sizing: border-box;
    padding-right: 0px;
  }
  @media ${device.laptop} {
    width: 65%;
    margin-left: 17.5%;
    margin-right: 17.5%;
    flex-direction: row;
    box-sizing: border-box;
    padding-right: 0px;
  }
  @media ${device.laptopL} {
    width: 65%;
    margin-left: 17.5%;
    margin-right: 17.5%;
    flex-direction: row;
    box-sizing: border-box;
    padding-right: 0px;
  }
  @media ${device.desktop} {
  }
  @media ${device.desktopL} {
  }
`;

const TileSub = styled.p`
  font-size: 16px;
  margin: 0;
  padding: 0;
  text-align: left;
  width: 100%;
  box-sizing: border-box;
  padding-left: 26px;
  padding-right: 12px;
  margin-top: 2px;
  font-family: "Lato", sans-serif;
  font-weight: 400;
`;

const Tile = styled.div`
  @media ${device.mobileS} {
    width: 100%;
    margin-top: 22px;
    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.075);
    cursor: pointer;

    :hover {
      box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.035);
    }
  }
  @media ${device.mobileM} {
    width: 100%;
    margin-top: 22px;
    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.075);
    cursor: pointer;

    :hover {
      box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.035);
    }
  }
  @media ${device.mobileL} {
    width: 100%;
    margin-top: 22px;
    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.075);
    cursor: pointer;

    :hover {
      box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.035);
    }
  }
  @media ${device.tablet} {
    width: fit-content;
    margin-top: 22px;
    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.075);
    cursor: pointer;

    :hover {
      box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.035);
    }
  }
  @media ${device.laptop} {
    width: fit-content;
    margin-top: 22px;
    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.075);
    cursor: pointer;

    :hover {
      box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.035);
    }
  }
  @media ${device.laptopL} {
    width: fit-content;
    margin-top: 22px;
    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.075);
    cursor: pointer;

    :hover {
      box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.035);
    }
  }
  @media ${device.desktop} {
    width: fit-content;
    margin-top: 22px;
    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.075);
    cursor: pointer;

    :hover {
      box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.035);
    }
  }
  @media ${device.desktopL} {
    width: fit-content;
    margin-top: 22px;
    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.075);
    cursor: pointer;

    :hover {
      box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.035);
    }
  }
`;

const TileContentS = styled.div`
  @media ${device.mobileS} {
    width: 100%;
    text-align: center;
    padding: 16px 22px 18px 0px;
    background-color: #f7f7f7;
    color: black;
    transition: ease-in-out 250ms;
    font-size: 12px;
    border-radius: 8px;
  }
  @media ${device.mobileM} {
    width: 100%;
    text-align: center;
    padding: 16px 22px 18px 0px;
    background-color: #f7f7f7;
    color: black;
    transition: ease-in-out 250ms;
    font-size: 12px;
    border-radius: 8px;
  }
  @media ${device.mobileL} {
    width: 100%;
    text-align: center;
    padding: 16px 22px 18px 0px;
    background-color: #f7f7f7;
    color: black;
    transition: ease-in-out 250ms;
    font-size: 12px;
    border-radius: 8px;
  }
  @media ${device.tablet} {
    width: 100%;
    text-align: center;
    padding: 16px 22px 18px 0px;
    background-color: #f7f7f7;
    color: black;
    transition: ease-in-out 250ms;
    font-size: 12px;
    border-radius: 8px;
  }
  @media ${device.laptop} {
    width: 100%;
    text-align: center;
    padding: 16px 22px 18px 0px;
    background-color: #f7f7f7;
    color: black;
    transition: ease-in-out 250ms;
    font-size: 12px;
    border-radius: 8px;
  }
  @media ${device.laptopL} {
    width: 100%;
    text-align: center;
    padding: 16px 22px 18px 0px;
    background-color: #f7f7f7;
    color: black;
    transition: ease-in-out 250ms;
    font-size: 12px;
    border-radius: 8px;
  }
  @media ${device.desktop} {
    width: 100%;
    text-align: center;
    padding: 16px 22px 18px 0px;
    background-color: #f7f7f7;
    color: black;
    transition: ease-in-out 250ms;
    font-size: 12px;
    border-radius: 8px;
  }
  @media ${device.desktopL} {
    width: 100%;
    text-align: center;
    padding: 16px 22px 18px 0px;
    background-color: #f7f7f7;
    color: black;
    transition: ease-in-out 250ms;
    font-size: 12px;
    border-radius: 8px;
  }
`;

const TilebtnM = styled.div`
  border-radius: 16px;
  border-style: solid;
  border-width: 6px;
  border-color: white !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
`;

const Intro = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  opacity: 1;
  @media ${device.mobileS} {
    padding-top: 10vh;
  }
  @media ${device.mobileM} {
    padding-top: 10vh;
  }
  @media ${device.mobileL} {
    padding-top: 10vh;
  }
  @media ${device.tablet} {
    padding-top: 0vh;
  }
  @media ${device.laptop} {
    padding-top: 0vh;
  }
  @media ${device.laptopL} {
    padding-top: 0vh;
  }
  @media ${device.desktop} {
    padding-top: 0vh;
  }
  @media ${device.desktopL} {
    padding-top: 0vh;
  }
`;

const ContactLink = styled.a`
  margin-left: 8px;
  background: linear-gradient(to right, #5c0084, #d83d99);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: ease-in-out 250ms;

  :hover {
    opacity: 0.5;
  }
`;

class Talky extends Component {
  componentDidMount() {
    var height = document.getElementById("white");
    height.classList.add("PHeight");
    height.classList.remove("HHeight");
    height.classList.remove("AHeight");
    height.classList.remove("WHeight");
  }
  render() {
    return (
      <SlumberContent>
        <DesktopSlumber>
          <div className="CaseContain">
            <SmallWave></SmallWave>
            <div className="Process">
              <ContentFrame style={{ paddingTop: "5vh" }}>
                <Fade bottom delay="250" distance="20px">
                  <SubtitleTwo className="header animated">
                    The Challenge
                  </SubtitleTwo>
                </Fade>
                <Fade bottom delay="250" distance="20px">
                  <DarkSubtitle>
                    Design a solution that helps kids learn to spell through
                    kinetic play
                  </DarkSubtitle>
                </Fade>
                <Fade bottom delay="250" distance="20px">
                  <Text>
                    Although there are many teaching spelling methods, few
                    currently focus on hands-on interaction (Kinetic play).
                    Therefore, with studies highlighting the kinetic play
                    method's efficiency, this project's challenge was to use
                    updated technology and tools to improve how children learn
                    to spell.
                  </Text>
                </Fade>
              </ContentFrame>
            </div>
            <Preview className="shadow talkypre" style={{ height: "60vh" }}>
              <Spacer></Spacer>
              <Spacer></Spacer>
            </Preview>
            <div className="Process" style={{ paddingTop: "20vh" }}>
              <ContentFrame>
                <Fade bottom delay="250" distance="20px">
                  <SubtitleTwo className="header animated">
                    Pain Points
                  </SubtitleTwo>
                </Fade>
                <Fade bottom delay="250" distance="20px">
                  <DarkSubtitle>Why is this a problem?</DarkSubtitle>
                </Fade>
                <Fade bottom delay="250" distance="20px">
                  <Text>
                    Studies in childhood education have shown that children
                    learn more effectively when taught concepts through kinetic
                    play. Unfortunately, current physical toys such as letter
                    blocks and flashcards don't provide all of the necessary
                    feedback leading to missed spelling education potential
                    (Visual, auditory, and physical at the same time).
                    Therefore, these toys are a prime choice for a re-designed
                    solution.
                  </Text>
                </Fade>
                <Spacer></Spacer>
                <Fade bottom delay="250" distance="20px">
                  <SubtitleTwo className="header animated">
                    User demographics
                  </SubtitleTwo>
                </Fade>
                <Fade bottom delay="250" distance="20px">
                  <DarkSubtitle>Starting with the audience</DarkSubtitle>
                </Fade>
                <Fade bottom delay="250" distance="20px">
                  <Text>
                    Since our goal was to create a product for early education,
                    it was clear that our target audience would be between the
                    ages of 3-5, when most children begin to learn to spell,
                    which means that all our decisions needed to reflect our
                    user's challenges. Avoiding complex actions and using simple
                    shapes while keeping options to a minimum.
                  </Text>
                </Fade>
                <Fade bottom delay="250" distance="20px">
                  <ImageM src={Step}></ImageM>
                </Fade>
                <Spacer></Spacer>
                <Fade bottom delay="250" distance="20px">
                  <SubtitleTwo className="header animated">
                    Brainstorming
                  </SubtitleTwo>
                </Fade>
                <Fade bottom delay="250" distance="20px">
                  <DarkSubtitle>Discovering the idea</DarkSubtitle>
                </Fade>
                <Fade bottom delay="250" distance="20px">
                  <Text>
                    Brainstorming multiple concepts as a team, we looked at
                    numerous different technologies and tools that could be used
                    or updated to tackle our challenge — some of these included
                    projection mapping, 3D printing, and RFID technology. We
                    were able to narrow further utilizing a decision matrix
                    where we determined three main factors.
                  </Text>
                </Fade>
                <Insights>
                  <Fade bottom delay="250" distance="20px">
                    <InsightItem>
                      <InsightImage className="InsightOneT"></InsightImage>
                      <InsightText>
                        Category <br></br> Play in education
                      </InsightText>
                    </InsightItem>
                  </Fade>
                  <Fade bottom delay="350" distance="20px">
                    <InsightItem>
                      <InsightImage className="InsightTwoT"></InsightImage>
                      <InsightText>
                        Area <br></br> Children & Learning
                      </InsightText>
                    </InsightItem>
                  </Fade>
                  <Fade bottom delay="450" distance="20px">
                    <InsightItem>
                      <InsightImage className="InsightThreeT"></InsightImage>
                      <InsightText>
                        Digital Media <br></br> Tactile, Visual, & auditory
                      </InsightText>
                    </InsightItem>
                  </Fade>
                </Insights>
                <Fade bottom delay="250" distance="20px">
                  <Text style={{ marginTop: "36px" }}>
                    From here, we determined an initial concept. Combining
                    physical letter blocks into an interactive game teaches the
                    audience to spell with visuals and tactile actions.
                  </Text>
                </Fade>
                <Fade bottom delay="250" distance="20px">
                  <ImageM src={Car}></ImageM>
                </Fade>
                <Spacer></Spacer>
                <Fade bottom delay="250" distance="20px">
                  <SubtitleTwo className="header animated">
                    FlowChart
                  </SubtitleTwo>
                </Fade>
                <Fade bottom delay="250" distance="20px">
                  <DarkSubtitle>Mapping the Interface</DarkSubtitle>
                </Fade>
                <Fade bottom delay="250" distance="20px">
                  <Text>
                    Working with Alex Thompson, we focused on the primary user
                    flow for the MVP. Mapping the flowchart for a finished
                    product and removing parts that seemed unnecessary for
                    developing the prototype. Filtering down our essential
                    features and providing a start for the layout of the
                    application.
                  </Text>
                </Fade>
                <Fade bottom delay="250" distance="20px">
                  <ImageM src={Flow}></ImageM>
                </Fade>
              </ContentFrame>
            </div>
            <PreviewL className="shadow">
              <ContentFrame>
                <Fade bottom delay="250" distance="20px">
                  <SubtitleTwo
                    className="header animated"
                    style={{ textAlign: "center" }}
                  >
                    Early Wireframing
                  </SubtitleTwo>
                </Fade>
                <Fade bottom delay="250" distance="20px">
                  <ImageM src={Wires}></ImageM>
                </Fade>
              </ContentFrame>
            </PreviewL>
            <div className="Process" style={{ paddingTop: "15vh" }}>
              <ContentFrame>
                <Fade bottom delay="250" distance="20px">
                  <SubtitleTwo className="header animated">
                    Development
                  </SubtitleTwo>
                </Fade>
                <Fade bottom delay="250" distance="20px">
                  <DarkSubtitle>Coding the MVP</DarkSubtitle>
                </Fade>
                <Fade bottom delay="250" distance="20px">
                  <Text>
                    As the primary developer, Lucas DiMonte used RFID technology
                    and the P5.js speech library to develop a prototype that
                    would visualize the letter on-screen and read the letter
                    aloud when a block is placed—later connecting this prototype
                    to the UI to complete our initial MVP.
                  </Text>
                </Fade>
              </ContentFrame>
            </div>
            <PreviewL className="blocks shadow">
              <ContentFrame>
                <Fade bottom delay="250" distance="20px">
                  <SubtitleTwo
                    className="header animated"
                    style={{ textAlign: "center" }}
                  >
                    3D Printing
                  </SubtitleTwo>
                </Fade>
                <Fade bottom delay="250" distance="20px">
                  <LText style={{ paddingTop: "22px", textAlign: "center" }}>
                    With the need to print the initial prototype, our concept
                    required 26 blocks and 104 letters. Creating one of each
                    letter of the alphabet with a letter on 4 of 6 faces per
                    block.
                  </LText>
                </Fade>
              </ContentFrame>
            </PreviewL>
            <div className="Process" style={{ paddingTop: "15vh" }}>
              <ContentFrame style={{ paddingBottom: "10vh" }}>
                <Fade bottom delay="250" distance="20px">
                  <SubtitleTwo className="header animated">
                    Prototype
                  </SubtitleTwo>
                </Fade>
                <Fade bottom delay="250" distance="20px">
                  <DarkSubtitle>Completing the MVP</DarkSubtitle>
                </Fade>
                <Fade bottom delay="250" distance="20px">
                  <Text>
                    Using the code, 3D printed blocks, and the RFID sensor, we
                    completed our final minimal viable product to work with a
                    few basic spelling examples. When interacted with, the
                    prototype would provide an image with the proper blank
                    letter spots that would include auditory and visual feedback
                    placing letters on the sensor.
                  </Text>
                </Fade>
                <Fade bottom delay="250" distance="20px">
                  <ImageM src={Mvp}></ImageM>
                </Fade>
                <Spacer></Spacer>
                <Fade bottom delay="250" distance="20px">
                  <SubtitleTwo className="header animated">
                    Project Outcome
                  </SubtitleTwo>
                </Fade>
                <Fade bottom delay="250" distance="20px">
                  <DarkSubtitle>What I learned</DarkSubtitle>
                </Fade>
                <Fade bottom delay="250" distance="20px">
                  <Text>
                    As a very collaborative project, talky blocks provided me
                    with experience working with multiple skillsets across the
                    development industry. Overall, it taught me the importance
                    of communication, collaborative-based decisions, and
                    supporting different project aspects, allowing me to explore
                    development support while also working collaboratively on
                    design activities to create the MVP.
                    <br></br>
                    <br></br>
                    Interested in discussing further?
                    <ContactLink href="mailto:crosbyadesign@gmail.com">
                      Let’s Talk
                    </ContactLink>
                  </Text>
                </Fade>
              </ContentFrame>
            </div>
          </div>
          <div className="CaseIntro">
            <ContentFrame>
              <Intro id="intro">
                <Fade bottom delay="450" distance="20px">
                  <Title className="header animated">Talky Blocks</Title>
                </Fade>
                <Fade bottom delay="500" distance="20px">
                  <LightSubtitle>Learning to spell through play.</LightSubtitle>
                </Fade>
                <Fade bottom delay="500" distance="20px">
                  <LightText>
                    Created as a high-fidelity product prototype, Talky Blocks
                    is an IoT spelling game focused on helping youth learn to
                    spell through kinetic play. Using a mix of physical
                    computing and digital interface systems to create an
                    interactive tactile, visual, and auditory experience.
                  </LightText>
                </Fade>
              </Intro>
            </ContentFrame>
          </div>
        </DesktopSlumber>
      </SlumberContent>
    );
  }
}

export default Talky;
