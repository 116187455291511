import React, { Component } from "react";
import styled from "styled-components";
import ScrollAnimation from "react-animate-on-scroll";
import $ from "jquery";

import Fade from "react-reveal/Fade";

const ScrollContain = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 0vh;
  position: absolute;
`;

class ScrollIcon extends Component {
  componentDidMount() {}
  render() {
    return (
      <ScrollContain>
        <div id="mouse" class="aniWrap">
          <div class="mouse">
            <div class="scroller"></div>
          </div>
        </div>
      </ScrollContain>
    );
  }
}

export default ScrollIcon;
