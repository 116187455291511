import React, { Component } from "react";
import MediaQuery from "react-responsive";
import styled from "styled-components";
import Fade from "react-reveal/Fade";
import { NavLink } from "react-router-dom";
import { device } from "../device.js";

import profile from "../images/profile.png";

const AboutContent = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  @media ${device.tablet} {
    padding-bottom: 80px;
  }
  @media ${device.laptop} {
    padding-bottom: 40px;
  }
  @media ${device.laptopL} {
    padding-bottom: 40px;
  }
  @media ${device.desktop} {
  }
  @media ${device.desktopL} {
  }
`;

const DesktopAbout = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;

  @media ${device.tablet} {
    padding-top: 10vh;
  }
  @media ${device.laptop} {
    padding-top: 10vh;
  }
  @media ${device.laptopL} {
    padding-top: 12.5vh;
  }
  @media ${device.desktop} {
  }
  @media ${device.desktopL} {
  }
`;

const MobileAbout = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding-top: 10vh;
  align-items: center;
  overflow: scroll;
`;

const ImageFrame = styled.div`
  background-color: white;
  border-style: solid;
  border-width: 6px;
  border-color: white;
  box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.075);

  @media ${device.mobileS} {
    width: 14vh;
    height: 14vh;
    border-radius: 8vh;
    overflow: hidden;
  }
  @media ${device.mobileM} {
    width: 14vh;
    height: 14vh;
    border-radius: 8vh;
    overflow: hidden;
  }
  @media ${device.mobileL} {
    width: 14vh;
    height: 14vh;
    border-radius: 8vh;
    overflow: hidden;
  }
  @media ${device.tablet} {
    width: 16vh;
    height: 16vh;
    border-radius: 10vh;
  }
  @media ${device.laptop} {
    width: 16vh;
    height: 16vh;
    border-radius: 10vh;
  }
  @media ${device.laptopL} {
    width: 16vh;
    height: 16vh;
    border-radius: 10vh;
  }
  @media ${device.desktop} {
  }
  @media ${device.desktopL} {
  }
`;

const ProfileBack = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Memoji = styled.img`
  width: 50%;
`;

const Subtitle = styled.h2`
  text-align: center;
  font-family: "Montserrat", sans-serif;

  @media ${device.mobileS} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    font-size: 16px;
    line-height: 30px;
    margin-top: 34px;
  }
  @media ${device.mobileM} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    font-size: 16px;
    line-height: 30px;
    margin-top: 34px;
  }
  @media ${device.mobileL} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    font-size: 16px;
    line-height: 30px;
    margin-top: 34px;
  }
  @media ${device.tablet} {
    width: 100%;
    font-size: 22px;
    line-height: 48px;
    margin-top: 34px;
  }
  @media ${device.laptop} {
    width: 100%;
    font-size: 22px;
    line-height: 48px;
    margin-top: 26px;
  }
  @media ${device.laptopL} {
    width: 100%;
    font-size: 22px;
    line-height: 48px;
    margin-top: 26px;
  }
  @media ${device.desktop} {
  }
  @media ${device.desktopL} {
  }
`;

const AboutText = styled.p`
  font-family: "Lato", sans-serif;
  line-height: 38px;
  text-align: left;
  @media ${device.mobileS} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    font-size: 14px;
    line-height: 30px;
  }
  @media ${device.mobileM} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    font-size: 14px;
    line-height: 30px;
  }
  @media ${device.mobileL} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    font-size: 14px;
    line-height: 30px;
  }
  @media ${device.tablet} {
    width: 70%;
    margin-left: 15%;
    margin-right: 15%;
    font-size: 16px;
    line-height: 38px;
  }
  @media ${device.laptop} {
    width: 65%;
    margin-left: 17.5%;
    margin-right: 17.5%;
    font-size: 16px;
    line-height: 38px;
  }
  @media ${device.laptopL} {
    width: 65%;
    margin-left: 17.5%;
    margin-right: 17.5%;
    font-size: 16px;
    line-height: 38px;
  }
  @media ${device.desktop} {
  }
  @media ${device.desktopL} {
  }
`;

const ContactLink = styled.a`
  margin-left: 8px;
  background: linear-gradient(to right, #5c0084, #d83d99);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: ease-in-out 250ms;

  :hover {
    opacity: 0.5;
  }
`;

class About extends Component {
  componentDidMount() {
    var height = document.getElementById("white");
    height.classList.add("AHeight");
    height.classList.remove("HHeight");
    height.classList.remove("WHeight");
    height.classList.remove("PHeight");
  }
  render() {
    return (
      <AboutContent>
        <MediaQuery minDeviceWidth={768}>
          <DesktopAbout>
            <ImageFrame className="fade-in">
              <ProfileBack className="Gcolor animated">
                <Memoji src={profile}></Memoji>
              </ProfileBack>
            </ImageFrame>
            <Fade bottom delay="650" distance="20px">
              <Subtitle>
                🎢 #ThemeParkEnthusiast, 🐶 #dogfather,<br></br> 🥅 #hockeyFan
              </Subtitle>
            </Fade>
            <Fade bottom delay="850" distance="20px">
              <AboutText>
                Previously a product design intern at SAP, I am consistently
                seeking new opportunities to make an impact. My experience
                includes supporting design projects across the SuccessFactors
                platform, including time management, UXR Reimagine, and human
                resource tools. I was also a founder of the Sheridan Hackademics
                club, receiving awards at multiple hackathons, including TD
                Elevate, the Canadian Music hackathon, Adobe Creative Jam, and
                Mississauga Tech & the City.
                <br></br>
                <br></br>
                Interested in discussing further?{" "}
                <ContactLink href="mailto:crosbyadesign@gmail.com">
                  Let’s Talk
                </ContactLink>
              </AboutText>
            </Fade>
          </DesktopAbout>
        </MediaQuery>
        <MediaQuery maxDeviceWidth={767}>
          <MobileAbout>
            <ImageFrame className="fade-in">
              <ProfileBack className="Gcolor animated">
                <Memoji src={profile}></Memoji>
              </ProfileBack>
            </ImageFrame>
            <Fade bottom delay="650" distance="20px">
              <Subtitle>
                🎢 #ThemeParkEnthusiast, 🐶 #dogfather,<br></br> 🥅 #hockeyFan
              </Subtitle>
            </Fade>
            <Fade bottom delay="850" distance="20px">
              <AboutText>
                Previously a product design intern at SAP, I am consistently
                seeking new opportunities to make an impact. My experience
                includes supporting design projects across the SuccessFactors
                platform, including time management, UXR Reimagine, and human
                resource tools. I was also a founder of the Sheridan Hackademics
                club, receiving awards at multiple hackathons, including TD
                Elevate, the Canadian Music hackathon, Adobe Creative Jam, and
                Mississauga Tech & the City.
                <br></br>
                <br></br>
                Interested in discussing further?{" "}
                <ContactLink href="mailto:crosbyadesign@gmail.com">
                  Let’s Talk
                </ContactLink>
              </AboutText>
            </Fade>
          </MobileAbout>
        </MediaQuery>
      </AboutContent>
    );
  }
}

export default About;
