import React, { Component } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { device } from "./device.js";
import "./App.css";
import styled from "styled-components";

import NavBar from "./components/navigation";
import Content from "./components/content";
import Footer from "./components/footer";

import AnimatedBackground from "./components/background";

const SiteShell = styled.div`
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  position: fixed;
  background-color: #0a0a0a;
  overflow: scroll;

  @media ${device.mobileS} {
  }
  @media ${device.mobileM} {
  }
  @media ${device.mobileL} {
  }
  @media ${device.tablet} {
  }
  @media ${device.laptop} {
  }
  @media ${device.laptopL} {
  }
  @media ${device.desktop} {
  }
  @media ${device.desktopL} {
  }
`;

const ImageBackground = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 2;
`;

class App extends Component {
  render() {
    return (
      <SiteShell>
        <BrowserRouter>
          <NavBar></NavBar>
          <Content></Content>
          <Footer></Footer>
        </BrowserRouter>
        <AnimatedBackground></AnimatedBackground>
        <ImageBackground id="backbg"></ImageBackground>
      </SiteShell>
    );
  }
}

export default App;
