import React, { Component } from "react";
import { Parallax } from "react-scroll-parallax";
import MediaQuery from "react-responsive";
import styled from "styled-components";
import Fade from "react-reveal/Fade";
import { NavLink } from "react-router-dom";
import { device } from "../device.js";

import PdfOne from "../pdfs/Crosby_Adam_IXDStudies1_Project1.pdf";
import PdfTwo from "../pdfs/Crosby_Adam_IXDStudies1_Project2.pdf";

import SmallWave from "../components/scrollwave";

import PreviewImg from "../images/phoneFrame.png";
import PreviewImgBack from "../images/slumber/slumber.gif";

import Analysis from "../images/slumber/Analysis.png";
import Spectrum from "../images/slumber/Spectrum.jpg";
import Story from "../images/slumber/story.jpg";
import Flow from "../images/slumber/flow.jpg";
import SMap from "../images/slumber/map.jpg";
import Wires from "../images/slumber/wires.png";
import Proto from "../images/slumber/mock.png";
import Complete from "../images/slumber/Complete.png";

const SlumberContent = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  @media ${device.tablet} {
    padding-bottom: 80px;
  }
  @media ${device.laptop} {
    padding-bottom: 40px;
  }
  @media ${device.laptopL} {
    padding-bottom: 40px;
  }
  @media ${device.desktop} {
  }
  @media ${device.desktopL} {
  }
`;

const DesktopSlumber = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: absolute;
  left: 0;

  @media ${device.tablet} {
    padding-top: 8vh;
  }
  @media ${device.laptop} {
    padding-top: 0px;
  }
  @media ${device.laptopL} {
    padding-top: 0px;
  }
  @media ${device.desktop} {
  }
  @media ${device.desktopL} {
  }
`;

const MobileSlumber = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding-top: 10vh;
  align-items: center;
  overflow: scroll;
`;

const Title = styled.h1`
  color: white;
  margin: 0;
  padding: 0;
  letter-spacing: 1px;
  font-family: "Montserrat", sans-serif;

  @media ${device.mobileS} {
    font-size: 44px;
    line-height: 66px;
    margin-top: 10.5vh;
  }
  @media ${device.mobileM} {
    font-size: 44px;
    line-height: 66px;
    margin-top: 10.5vh;
  }
  @media ${device.mobileL} {
    font-size: 44px;
    line-height: 66px;
    margin-top: 10.5vh;
  }
  @media ${device.tablet} {
    font-size: 66px;
    line-height: 88px;
    margin-top: 10.5vh;
  }
  @media ${device.laptop} {
    font-size: 76px;
    line-height: 88px;
    margin-top: 17.5vh;
  }
  @media ${device.laptopL} {
    font-size: 76px;
    line-height: 108px;
    margin-top: 17.5vh;
  }
  @media ${device.desktop} {
    font-size: 88px;
    line-height: 88px;
    margin-top: 17.5vh;
  }
  @media ${device.desktopL} {
    font-size: 88px;
    line-height: 88px;
    margin-top: 17.5vh;
  }
`;

const ContentFrame = styled.div`
  width: 100%;
  min-height: fit-content;
  box-sizing: border-box;

  @media ${device.mobileS} {
    padding-left: 22px;
    padding-right: 22px;
  }
  @media ${device.mobileM} {
    padding-left: 22px;
    padding-right: 22px;
  }
  @media ${device.mobileL} {
    padding-left: 22px;
    padding-right: 22px;
  }
  @media ${device.tablet} {
    padding-left: 8%;
    padding-right: 8%;
  }
  @media ${device.laptop} {
    padding-left: 15%;
    padding-right: 15%;
  }
  @media ${device.laptopL} {
    padding-left: 15%;
    padding-right: 15%;
  }
  @media ${device.desktop} {
    padding-left: 15%;
    padding-right: 15%;
  }
  @media ${device.desktopL} {
    padding-left: 15%;
    padding-right: 15%;
  }
`;

const LightSubtitle = styled.h2`
  text-align: center;
  font-family: "Montserrat", sans-serif;
  color: white;

  @media ${device.mobileS} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    font-size: 16px;
    line-height: 30px;
    margin-top: 34px;
    margin-bottom: 36px;
  }
  @media ${device.mobileM} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    font-size: 16px;
    line-height: 30px;
    margin-top: 18px;
    margin-bottom: 36px;
  }
  @media ${device.mobileL} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    font-size: 16px;
    line-height: 30px;
    margin-top: 18px;
    margin-bottom: 36px;
  }
  @media ${device.tablet} {
    width: 100%;
    font-size: 22px;
    line-height: 48px;
    margin-top: 22px;
    margin-bottom: 0px;
  }
  @media ${device.laptop} {
    width: 100%;
    font-size: 22px;
    line-height: 48px;
    margin-top: 22px;
    margin-bottom: 0px;
  }
  @media ${device.laptopL} {
    width: 100%;
    font-size: 22px;
    line-height: 48px;
    margin-top: 22px;
    text-align: center;
    margin-bottom: 0px;
  }
  @media ${device.desktop} {
  }
  @media ${device.desktopL} {
  }
`;

const SubtitleTwo = styled.h3`
  text-align: left;
  font-family: "Montserrat", sans-serif;
  color: black;

  @media ${device.mobileS} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    font-size: 16px;
    line-height: 30px;
    margin-top: 34px;
  }
  @media ${device.mobileM} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    font-size: 16px;
    line-height: 30px;
    margin-top: 18px;
    margin-bottom: 0px;
  }
  @media ${device.mobileL} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    font-size: 16px;
    line-height: 30px;
    margin-top: 18px;
    margin-bottom: 0px;
  }
  @media ${device.tablet} {
    width: 70%;
    margin-left: 15%;
    margin-right: 15%;
    font-size: 22px;
    line-height: 48px;
    margin-top: 22px;
    margin-bottom: 0px;
  }
  @media ${device.laptop} {
    width: 65%;
    margin-left: 17.5%;
    margin-right: 17.5%;
    font-size: 22px;
    line-height: 52px;
    margin-top: 22px;
    margin-bottom: 22px;
  }
  @media ${device.laptopL} {
    width: 65%;
    margin-left: 17.5%;
    margin-right: 17.5%;
    font-size: 22px;
    line-height: 52px;
    margin-top: 22px;
    margin-bottom: 22px;
  }
  @media ${device.desktop} {
  }
  @media ${device.desktopL} {
  }
`;

const DarkSubtitle = styled.h2`
  text-align: left;
  font-family: "Montserrat", sans-serif;
  color: black;

  @media ${device.mobileS} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    font-size: 16px;
    line-height: 30px;
    margin-top: 34px;
  }
  @media ${device.mobileM} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    font-size: 16px;
    line-height: 30px;
    margin-top: 18px;
    margin-bottom: 0px;
  }
  @media ${device.mobileL} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    font-size: 16px;
    line-height: 30px;
    margin-top: 18px;
    margin-bottom: 0px;
  }
  @media ${device.tablet} {
    width: 70%;
    margin-left: 15%;
    margin-right: 15%;
    font-size: 22px;
    line-height: 48px;
    margin-top: 4px;
    margin-bottom: 0px;
  }
  @media ${device.laptop} {
    width: 65%;
    margin-left: 17.5%;
    margin-right: 17.5%;
    font-size: 26px;
    line-height: 52px;
    margin-top: 22px;
    margin-bottom: 22px;
  }
  @media ${device.laptopL} {
    width: 65%;
    margin-left: 17.5%;
    margin-right: 17.5%;
    font-size: 26px;
    line-height: 52px;
    margin-top: 22px;
    margin-bottom: 22px;
  }
  @media ${device.desktop} {
  }
  @media ${device.desktopL} {
  }
`;

const LSubtitle = styled.h2`
  text-align: left;
  font-family: "Montserrat", sans-serif;
  color: white;

  @media ${device.mobileS} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    font-size: 16px;
    line-height: 30px;
    margin-top: 34px;
  }
  @media ${device.mobileM} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    font-size: 16px;
    line-height: 30px;
    margin-top: 18px;
    margin-bottom: 0px;
  }
  @media ${device.mobileL} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    font-size: 16px;
    line-height: 30px;
    margin-top: 18px;
    margin-bottom: 0px;
  }
  @media ${device.tablet} {
    width: 70%;
    margin-left: 15%;
    margin-right: 15%;
    font-size: 22px;
    line-height: 48px;
    margin-top: 22px;
    margin-bottom: 0px;
  }
  @media ${device.laptop} {
    width: 65%;
    margin-left: 17.5%;
    margin-right: 17.5%;
    font-size: 26px;
    line-height: 52px;
    margin-top: 22px;
    margin-bottom: 22px;
  }
  @media ${device.laptopL} {
    width: 65%;
    margin-left: 17.5%;
    margin-right: 17.5%;
    font-size: 26px;
    line-height: 52px;
    margin-top: 22px;
    margin-bottom: 22px;
  }
  @media ${device.desktop} {
  }
  @media ${device.desktopL} {
  }
`;

const LightText = styled.p`
  font-family: "Lato", sans-serif;
  line-height: 38px;
  text-align: center;
  color: white;

  @media ${device.mobileS} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    font-size: 14px;
    line-height: 30px;
    text-align: left;
  }
  @media ${device.mobileM} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    font-size: 14px;
    line-height: 30px;
    text-align: left;
  }
  @media ${device.mobileL} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    font-size: 14px;
    line-height: 30px;
    text-align: left;
  }
  @media ${device.tablet} {
    width: 70%;
    margin-left: 15%;
    margin-right: 15%;
    font-size: 16px;
    line-height: 38px;
    text-align: center;
  }
  @media ${device.laptop} {
    width: 65%;
    margin-left: 17.5%;
    margin-right: 17.5%;
    font-size: 16px;
    line-height: 38px;
    text-align: center;
  }
  @media ${device.laptopL} {
    width: 65%;
    margin-left: 17.5%;
    margin-right: 17.5%;
    font-size: 16px;
    line-height: 38px;
    text-align: center;
  }
  @media ${device.desktop} {
  }
  @media ${device.desktopL} {
  }
`;

const Text = styled.p`
  font-family: "Lato", sans-serif;
  line-height: 38px;
  text-align: left;
  color: black;

  @media ${device.mobileS} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    font-size: 14px;
    line-height: 30px;
  }
  @media ${device.mobileM} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    font-size: 14px;
    line-height: 30px;
  }
  @media ${device.mobileL} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    font-size: 14px;
    line-height: 30px;
  }
  @media ${device.tablet} {
    width: 70%;
    margin-left: 15%;
    margin-right: 15%;
    font-size: 16px;
    line-height: 38px;
    margin-top: 16px;
  }
  @media ${device.laptop} {
    width: 65%;
    margin-left: 17.5%;
    margin-right: 17.5%;
    font-size: 16px;
    line-height: 38px;
  }
  @media ${device.laptopL} {
    width: 65%;
    margin-left: 17.5%;
    margin-right: 17.5%;
    font-size: 16px;
    line-height: 38px;
  }
  @media ${device.desktop} {
  }
  @media ${device.desktopL} {
  }
`;

const LText = styled.p`
  font-family: "Lato", sans-serif;
  line-height: 38px;
  text-align: left;
  color: white;

  @media ${device.mobileS} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    font-size: 14px;
    line-height: 30px;
  }
  @media ${device.mobileM} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    font-size: 14px;
    line-height: 30px;
  }
  @media ${device.mobileL} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    font-size: 14px;
    line-height: 30px;
  }
  @media ${device.tablet} {
    width: 70%;
    margin-left: 15%;
    margin-right: 15%;
    font-size: 16px;
    line-height: 38px;
  }
  @media ${device.laptop} {
    width: 65%;
    margin-left: 17.5%;
    margin-right: 17.5%;
    font-size: 16px;
    line-height: 38px;
  }
  @media ${device.laptopL} {
    width: 65%;
    margin-left: 17.5%;
    margin-right: 17.5%;
    font-size: 16px;
    line-height: 38px;
  }
  @media ${device.desktop} {
  }
  @media ${device.desktopL} {
  }
`;

const Preview = styled.div`
  width: 100%;
  height: 50vh;
  background-color: #0a0a0a;
  z-index: 50;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${device.mobileS} {
    height: 40vh;
  }
  @media ${device.mobileM} {
    height: 40vh;
  }
  @media ${device.mobileL} {
    height: 40vh;
  }
  @media ${device.tablet} {
    height: 50vh;
  }
  @media ${device.laptop} {
    height: 50vh;
  }
  @media ${device.laptopL} {
    height: 50vh;
  }
  @media ${device.desktop} {
  }
  @media ${device.desktopL} {
  }
`;

const PreviewImage = styled.img`
  height: 65vh;
  position: absolute;
`;

const PreviewImageBack = styled.img`
  height: 62.5vh;
  position: absolute;
  border-radius: 3vh;
`;

const PreviewL = styled.div`
  width: 100%;
  height: fit-content;
  background-color: #0a0a0a;
  z-index: 50;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10vh;
  padding-bottom: 10vh;
`;

const Insights = styled.div`
  @media ${device.mobileS} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
  }
  @media ${device.mobileM} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
  }
  @media ${device.mobileL} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
  }
  @media ${device.tablet} {
    width: 70%;
    margin-left: 15%;
    margin-right: 15%;
    height: fit-content;
    display: grid;
    grid-column-gap: 22px;
    grid-template-columns: auto auto auto;
    margin-top: 0px;
  }
  @media ${device.laptop} {
    width: 65%;
    margin-left: 17.5%;
    margin-right: 17.5%;
    height: fit-content;
    display: grid;
    grid-column-gap: 22px;
    grid-template-columns: auto auto auto;
    margin-top: 0px;
  }
  @media ${device.laptopL} {
    width: 65%;
    margin-left: 17.5%;
    margin-right: 17.5%;
    height: fit-content;
    display: grid;
    grid-column-gap: 22px;
    grid-template-columns: auto auto auto;
    margin-top: 52px;
  }
  @media ${device.desktop} {
    height: fit-content;
    display: grid;
    grid-column-gap: 22px;
    grid-template-columns: auto auto auto;
    margin-top: 52px;
  }
  @media ${device.desktopL} {
    height: fit-content;
    display: grid;
    grid-column-gap: 22px;
    grid-template-columns: auto auto auto;
    margin-top: 52px;
  }
`;

const InsightItem = styled.div`
  @media ${device.mobileS} {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 44px;
    width: 85%;
    margin-left: 7.5%;
  }
  @media ${device.mobileM} {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 44px;
    width: 85%;
    margin-left: 7.5%;
  }
  @media ${device.mobileL} {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 44px;
    width: 85%;
    margin-left: 7.5%;
  }
  @media ${device.tablet} {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-left: 0%;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
  }
  @media ${device.laptop} {
    height: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-left: 0%;
  }
  @media ${device.laptopL} {
    height: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-left: 0%;
  }
  @media ${device.desktop} {
    height: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-left: 0%;
  }
  @media ${device.desktopL} {
    height: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-left: 0%;
  }
`;

const InsightImage = styled.div`
  @media ${device.mobileS} {
    width: 15vh;
    height: 0;
    padding-bottom: 15vh;
    background-position: center;
    background-size: cover;
    border-radius: 50%;
    border-style: solid;
    border-color: white;
    border-width: 6px;
    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.075);
    margin-bottom: 22px;
  }
  @media ${device.mobileM} {
    width: 15vh;
    height: 0;
    padding-bottom: 15vh;
    background-position: center;
    background-size: cover;
    border-radius: 50%;
    border-style: solid;
    border-color: white;
    border-width: 6px;
    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.075);
    margin-bottom: 22px;
  }
  @media ${device.mobileL} {
    width: 15vh;
    height: 0;
    padding-bottom: 15vh;
    background-position: center;
    background-size: cover;
    border-radius: 50%;
    border-style: solid;
    border-color: white;
    border-width: 6px;
    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.075);
    margin-bottom: 22px;
  }
  @media ${device.tablet} {
    width: 15vh;
    height: 0;
    padding-bottom: 15vh;
    background-position: center;
    background-size: cover;
    border-radius: 50%;
    border-style: solid;
    border-color: white;
    border-width: 6px;
    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.075);
    margin-bottom: 22px;
  }
  @media ${device.laptop} {
    width: 15vh;
    height: 0;
    padding-bottom: 15vh;
    background-position: center;
    background-size: cover;
    border-radius: 50%;
    border-style: solid;
    border-color: white;
    border-width: 6px;
    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.075);
    margin-bottom: 22px;
  }
  @media ${device.laptopL} {
    width: 15vh;
    height: 0;
    padding-bottom: 15vh;
    background-position: center;
    background-size: cover;
    border-radius: 50%;
    border-style: solid;
    border-color: white;
    border-width: 6px;
    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.075);
    margin-bottom: 22px;
  }
  @media ${device.desktop} {
    width: 15vh;
    height: 0;
    padding-bottom: 15vh;
    background-position: center;
    background-size: cover;
    border-radius: 50%;
    border-style: solid;
    border-color: white;
    border-width: 6px;
    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.075);
    margin-bottom: 22px;
  }
  @media ${device.desktopL} {
    width: 15vh;
    height: 0;
    padding-bottom: 15vh;
    background-position: center;
    background-size: cover;
    border-radius: 50%;
    border-style: solid;
    border-color: white;
    border-width: 6px;
    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.075);
    margin-bottom: 22px;
  }
`;

const InsightText = styled.p`
  @media ${device.mobileS} {
    font-size: 14px;
    line-height: 26px;
    text-align: center;
    font-weight: 700;
  }
  @media ${device.mobileM} {
    font-size: 14px;
    line-height: 26px;
    text-align: center;
    font-weight: 700;
  }
  @media ${device.mobileL} {
    font-size: 14px;
    line-height: 26px;
    text-align: center;
    font-weight: 700;
  }
  @media ${device.tablet} {
    font-size: 12px;
    line-height: 26px;
    text-align: center;
    font-weight: 700;
  }
  @media ${device.laptop} {
    font-size: 12px;
    line-height: 26px;
    text-align: center;
    font-weight: 700;
  }
  @media ${device.laptopL} {
    font-size: 12px;
    line-height: 26px;
    text-align: center;
    font-weight: 700;
  }
  @media ${device.desktop} {
    font-size: 12px;
    line-height: 26px;
    text-align: center;
    font-weight: 700;
  }
  @media ${device.desktopL} {
    font-size: 12px;
    line-height: 26px;
    text-align: center;
    font-weight: 700;
  }
`;

const Spacer = styled.div`
  @media ${device.mobileS} {
    width: 100%;
    height: 10vh;
  }
  @media ${device.mobileM} {
    width: 100%;
    height: 10vh;
  }
  @media ${device.mobileL} {
    width: 100%;
    height: 10vh;
  }
  @media ${device.tablet} {
    width: 100%;
    height: 20vh;
  }
  @media ${device.laptop} {
    width: 100%;
    height: 20vh;
  }
  @media ${device.laptopL} {
    width: 100%;
    height: 20vh;
  }
  @media ${device.desktop} {
    width: 100%;
    height: 20vh;
  }
  @media ${device.desktopL} {
    width: 100%;
    height: 20vh;
  }
`;

const ImageM = styled.img`
  margin-top: 22px;

  @media ${device.mobileS} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
  }
  @media ${device.mobileM} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
  }
  @media ${device.mobileL} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
  }
  @media ${device.tablet} {
    width: 70%;
    margin-left: 15%;
    margin-right: 15%;
  }
  @media ${device.laptop} {
    width: 65%;
    margin-left: 17.5%;
    margin-right: 17.5%;
  }
  @media ${device.laptopL} {
    width: 65%;
    margin-left: 17.5%;
    margin-right: 17.5%;
  }
  @media ${device.desktop} {
  }
  @media ${device.desktopL} {
  }
`;

const ReportFrame = styled.div`
  display: flex;
  flex-direction: row;
  @media ${device.mobileS} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    flex-direction: column;
    box-sizing: border-box;
    padding-right: 16px;
  }
  @media ${device.mobileM} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    flex-direction: column;
    box-sizing: border-box;
    padding-right: 16px;
  }
  @media ${device.mobileL} {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    flex-direction: column;
    box-sizing: border-box;
    padding-right: 16px;
  }
  @media ${device.tablet} {
    width: 70%;
    margin-left: 15%;
    margin-right: 15%;
    flex-direction: row;
    box-sizing: border-box;
    padding-right: 0px;
  }
  @media ${device.laptop} {
    width: 65%;
    margin-left: 17.5%;
    margin-right: 17.5%;
    flex-direction: row;
    box-sizing: border-box;
    padding-right: 0px;
  }
  @media ${device.laptopL} {
    width: 65%;
    margin-left: 17.5%;
    margin-right: 17.5%;
    flex-direction: row;
    box-sizing: border-box;
    padding-right: 0px;
  }
  @media ${device.desktop} {
  }
  @media ${device.desktopL} {
  }
`;

const TileSub = styled.p`
  font-size: 16px;
  margin: 0;
  padding: 0;
  text-align: left;
  width: 100%;
  box-sizing: border-box;
  padding-left: 26px;
  padding-right: 12px;
  margin-top: 2px;
  font-family: "Lato", sans-serif;
  font-weight: 400;
`;

const Tile = styled.div`
  @media ${device.mobileS} {
    width: 100%;
    margin-top: 22px;
    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.075);
    cursor: pointer;

    :hover {
      box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.035);
    }
  }
  @media ${device.mobileM} {
    width: 100%;
    margin-top: 22px;
    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.075);
    cursor: pointer;

    :hover {
      box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.035);
    }
  }
  @media ${device.mobileL} {
    width: 100%;
    margin-top: 22px;
    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.075);
    cursor: pointer;

    :hover {
      box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.035);
    }
  }
  @media ${device.tablet} {
    width: fit-content;
    margin-top: 22px;
    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.075);
    cursor: pointer;

    :hover {
      box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.035);
    }
  }
  @media ${device.laptop} {
    width: fit-content;
    margin-top: 22px;
    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.075);
    cursor: pointer;

    :hover {
      box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.035);
    }
  }
  @media ${device.laptopL} {
    width: fit-content;
    margin-top: 22px;
    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.075);
    cursor: pointer;

    :hover {
      box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.035);
    }
  }
  @media ${device.desktop} {
    width: fit-content;
    margin-top: 22px;
    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.075);
    cursor: pointer;

    :hover {
      box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.035);
    }
  }
  @media ${device.desktopL} {
    width: fit-content;
    margin-top: 22px;
    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.075);
    cursor: pointer;

    :hover {
      box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.035);
    }
  }
`;

const TileContentS = styled.div`
  @media ${device.mobileS} {
    width: 100%;
    text-align: center;
    padding: 16px 22px 18px 0px;
    background-color: #f7f7f7;
    color: black;
    transition: ease-in-out 250ms;
    font-size: 12px;
    border-radius: 8px;
  }
  @media ${device.mobileM} {
    width: 100%;
    text-align: center;
    padding: 16px 22px 18px 0px;
    background-color: #f7f7f7;
    color: black;
    transition: ease-in-out 250ms;
    font-size: 12px;
    border-radius: 8px;
  }
  @media ${device.mobileL} {
    width: 100%;
    text-align: center;
    padding: 16px 22px 18px 0px;
    background-color: #f7f7f7;
    color: black;
    transition: ease-in-out 250ms;
    font-size: 12px;
    border-radius: 8px;
  }
  @media ${device.tablet} {
    width: 100%;
    text-align: center;
    padding: 16px 22px 18px 0px;
    background-color: #f7f7f7;
    color: black;
    transition: ease-in-out 250ms;
    font-size: 12px;
    border-radius: 8px;
  }
  @media ${device.laptop} {
    width: 100%;
    text-align: center;
    padding: 16px 22px 18px 0px;
    background-color: #f7f7f7;
    color: black;
    transition: ease-in-out 250ms;
    font-size: 12px;
    border-radius: 8px;
  }
  @media ${device.laptopL} {
    width: 100%;
    text-align: center;
    padding: 16px 22px 18px 0px;
    background-color: #f7f7f7;
    color: black;
    transition: ease-in-out 250ms;
    font-size: 12px;
    border-radius: 8px;
  }
  @media ${device.desktop} {
    width: 100%;
    text-align: center;
    padding: 16px 22px 18px 0px;
    background-color: #f7f7f7;
    color: black;
    transition: ease-in-out 250ms;
    font-size: 12px;
    border-radius: 8px;
  }
  @media ${device.desktopL} {
    width: 100%;
    text-align: center;
    padding: 16px 22px 18px 0px;
    background-color: #f7f7f7;
    color: black;
    transition: ease-in-out 250ms;
    font-size: 12px;
    border-radius: 8px;
  }
`;

const TilebtnM = styled.div`
  border-radius: 16px;
  border-style: solid;
  border-width: 6px;
  border-color: white !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
`;

class Slumber extends Component {
  componentDidMount() {
    var height = document.getElementById("white");
    height.classList.add("PHeight");
    height.classList.remove("HHeight");
    height.classList.remove("AHeight");
    height.classList.remove("WHeight");
  }
  render() {
    return (
      <SlumberContent>
        <MediaQuery minDeviceWidth={768}>
          <DesktopSlumber>
            <div className="CaseContain">
              <SmallWave></SmallWave>
              <div className="Process">
                <ContentFrame style={{ paddingTop: "5vh" }}>
                  <Fade bottom delay="250" distance="20px">
                    <SubtitleTwo className="header animated">
                      The Challenge
                    </SubtitleTwo>
                  </Fade>
                  <Fade bottom delay="250" distance="20px">
                    <DarkSubtitle>
                      Design & develop a solution that helps type 01 diabetics
                      improve their sleep.
                    </DarkSubtitle>
                  </Fade>
                  <Fade bottom delay="250" distance="20px">
                    <Text>
                      While there are many sleep tracking applications
                      available, there is no current solution that accounts for
                      factors such as blood glucose levels. Therefore, this
                      project's goal was to create a sleep tracking solution
                      that better matches the needs of diagnosed type 01
                      diabetics. This application would require a customizable &
                      easy use experience tailored to the users' needs and
                      management tools.
                    </Text>
                  </Fade>
                </ContentFrame>
              </div>
              <Preview>
                <PreviewImageBack src={PreviewImgBack}></PreviewImageBack>
                <PreviewImage src={PreviewImg}></PreviewImage>
              </Preview>
              <div className="Process" style={{ paddingTop: "20vh" }}>
                <ContentFrame>
                  <Fade bottom delay="250" distance="20px">
                    <SubtitleTwo className="header animated">
                      Pain Points
                    </SubtitleTwo>
                  </Fade>
                  <Fade bottom delay="250" distance="20px">
                    <DarkSubtitle>Glucose management is tricky</DarkSubtitle>
                  </Fade>
                  <Fade bottom delay="250" distance="20px">
                    <Text>
                      For people with type 01 diabetes, glucose management is an
                      everyday task, accounting for many different variables to
                      avoid high or low blood sugar levels. To further
                      understand the audience's barriers, I conducted secondary
                      and primary research providing the following insights:
                    </Text>
                  </Fade>
                  <Insights>
                    <Fade bottom delay="250" distance="20px">
                      <InsightItem>
                        <InsightImage className="InsightOne"></InsightImage>
                        <InsightText>
                          Poor sleep lowers insulin efficiency and high or low
                          glucose levels affect sleep.
                        </InsightText>
                      </InsightItem>
                    </Fade>
                    <Fade bottom delay="350" distance="20px">
                      <InsightItem>
                        <InsightImage className="InsightTwo"></InsightImage>
                        <InsightText>
                          Real-time glucose management is often the most
                          difficult to achieve.
                        </InsightText>
                      </InsightItem>
                    </Fade>
                    <Fade bottom delay="450" distance="20px">
                      <InsightItem>
                        <InsightImage className="InsightThree"></InsightImage>
                        <InsightText>
                          People with type 01 diabetes develop routines based
                          off past experiences.
                        </InsightText>
                      </InsightItem>
                    </Fade>
                  </Insights>
                  <Spacer></Spacer>
                  <Fade bottom delay="250" distance="20px">
                    <SubtitleTwo className="header animated">
                      Market Analysis
                    </SubtitleTwo>
                  </Fade>
                  <Fade bottom delay="250" distance="20px">
                    <DarkSubtitle>What’s currently missing?</DarkSubtitle>
                  </Fade>
                  <Fade bottom delay="250" distance="20px">
                    <Text>
                      I conducted a market analysis based on my user research to
                      determine what is available and what is missing for those
                      looking to manage blood glucose levels and
                      sleep—visualizing this in the table below.
                    </Text>
                  </Fade>
                  <Fade bottom delay="250" distance="20px">
                    <ImageM src={Analysis}></ImageM>
                  </Fade>
                  <Spacer></Spacer>
                  <Fade bottom delay="250" distance="20px">
                    <SubtitleTwo className="header animated">
                      Persona Spectrum
                    </SubtitleTwo>
                  </Fade>
                  <Fade bottom delay="250" distance="20px">
                    <DarkSubtitle>
                      Busy diabetics struggle with sleep
                    </DarkSubtitle>
                  </Fade>
                  <Fade bottom delay="250" distance="20px">
                    <Text>
                      Taking the interview results into account, I created a
                      persona spectrum to determine the variety of goals,
                      motivation, and technology of the target audience.
                    </Text>
                  </Fade>
                  <Fade bottom delay="250" distance="20px">
                    <ImageM src={Spectrum}></ImageM>
                  </Fade>
                  <Spacer></Spacer>
                  <Fade bottom delay="250" distance="20px">
                    <SubtitleTwo className="header animated">
                      Storyboarding
                    </SubtitleTwo>
                  </Fade>
                  <Fade bottom delay="250" distance="20px">
                    <DarkSubtitle>AI could improve sleep quality</DarkSubtitle>
                  </Fade>
                  <Fade bottom delay="250" distance="20px">
                    <Text style={{ paddingBottom: "22px" }}>
                      Creating a storyboard, I determined that the application
                      would require basic AI to provide actionable suggestions
                      through user interactions.
                    </Text>
                  </Fade>
                  <Fade bottom delay="250" distance="20px">
                    <ImageM src={Story}></ImageM>
                  </Fade>
                  <Spacer style={{ paddingBottom: "22px" }}></Spacer>
                  <Fade bottom delay="250" distance="20px">
                    <SubtitleTwo className="header animated">
                      User Flows
                    </SubtitleTwo>
                  </Fade>
                  <Fade bottom delay="250" distance="20px">
                    <DarkSubtitle>
                      Visualizing glucose & sleep routines
                    </DarkSubtitle>
                  </Fade>
                  <Fade bottom delay="450" distance="20px">
                    <ImageM src={Flow}></ImageM>
                  </Fade>
                  <Fade bottom delay="250" distance="20px">
                    <Text style={{ paddingTop: "22px" }}>
                      Supporting the target audience, I designed the application
                      MVP to have three main use cases. These included
                      connecting management tools, viewing sleep patterns, and
                      updating profile information.
                    </Text>
                  </Fade>
                  <Spacer></Spacer>
                  <Fade bottom delay="250" distance="20px">
                    <SubtitleTwo className="header animated">
                      Site Mapping
                    </SubtitleTwo>
                  </Fade>
                  <Fade bottom delay="250" distance="20px">
                    <DarkSubtitle>Personalizing recommendations</DarkSubtitle>
                  </Fade>
                  <Fade bottom delay="250" distance="20px">
                    <ImageM src={SMap}></ImageM>
                  </Fade>
                  <Fade bottom delay="250" distance="20px">
                    <Text style={{ paddingTop: "22px" }}>
                      Integrating various data sets, I created a sitemap to
                      visualize how the application would implement personalized
                      data. Providing further insight into the architecture of
                      the solution.
                    </Text>
                  </Fade>
                </ContentFrame>
              </div>
              <PreviewL>
                <ContentFrame>
                  <Fade bottom delay="250" distance="20px">
                    <SubtitleTwo className="header animated">
                      Wireframing & A | B testing
                    </SubtitleTwo>
                  </Fade>
                  <Fade bottom delay="250" distance="20px">
                    <LSubtitle style={{ textAlign: "left" }}>
                      Building customized solutions
                    </LSubtitle>
                  </Fade>
                  <Fade bottom delay="250" distance="20px">
                    <ImageM src={Wires}></ImageM>
                  </Fade>
                  <Fade bottom delay="250" distance="20px">
                    <LText style={{ paddingTop: "22px" }}>
                      Gathering user feedback, I created multiple early
                      wireframes to conduct A/B testing. Resulting in critical
                      future design feedback, including the importance of color
                      choice and the need to design clear data visualizations.
                    </LText>
                  </Fade>
                </ContentFrame>
              </PreviewL>
              <div
                className="Process"
                style={{ paddingTop: "15vh", paddingBottom: "20vh" }}
              >
                <ContentFrame>
                  <Fade bottom delay="250" distance="20px">
                    <SubtitleTwo className="header animated">
                      High-fidelity Mockups
                    </SubtitleTwo>
                  </Fade>
                  <Fade bottom delay="250" distance="20px">
                    <DarkSubtitle>Creating the application design</DarkSubtitle>
                  </Fade>
                  <Fade bottom delay="250" distance="20px">
                    <Text>
                      Implementing the gathered feedback, the next step of this
                      project is to begin the early prototype design phase.
                      Focused on exploring data visualization, style, and color
                      that can efficiently solve for the target audience.
                    </Text>
                  </Fade>
                  <Fade bottom delay="250" distance="20px">
                    <ImageM src={Proto}></ImageM>
                  </Fade>
                  <Spacer></Spacer>
                  <Fade bottom delay="250" distance="20px">
                    <SubtitleTwo className="header animated">
                      Feedback
                    </SubtitleTwo>
                  </Fade>
                  <Fade bottom delay="250" distance="20px">
                    <DarkSubtitle>Updating the designs</DarkSubtitle>
                  </Fade>
                  <Fade bottom delay="250" distance="20px">
                    <Text>
                      Using the previous phase in a cognitive walkthrough, I
                      significantly updated my designs to reflect the project's
                      goals better. In particular, I focused on the daily
                      summary and creating a call to action that would help the
                      user improve their routine.
                    </Text>
                  </Fade>
                  <Fade bottom delay="250" distance="20px">
                    <ImageM src={Complete}></ImageM>
                  </Fade>
                  <Spacer></Spacer>
                  <Fade bottom delay="250" distance="20px">
                    <SubtitleTwo className="header animated">
                      Project Outcome
                    </SubtitleTwo>
                  </Fade>
                  <Fade bottom delay="250" distance="20px">
                    <DarkSubtitle>Design Reflection</DarkSubtitle>
                  </Fade>
                  <Fade bottom delay="250" distance="20px">
                    <Text>
                      Overall, Slumber was able to achieve the goals related to
                      creating strong visual comparisons between glucose and
                      sleep routines. In the future, this solution would look to
                      explore IOS components such as widgets, push
                      notifications, and apple watch designs to better the
                      overall user experience. However, in it's current state,
                      Slumber is a solid example of how we can use current
                      technologies to improve people's ability to manage their
                      health and routines.<br></br>
                      <br></br>
                      For more information, feel free to download my capstone
                      report iterations.
                    </Text>
                  </Fade>
                  <ReportFrame>
                    <Fade bottom delay="250" distance="20px">
                      <a
                        href={PdfOne}
                        target="_blank"
                        style={{ textDecoration: "none" }}
                      >
                        <Tile id="Tilebtn" className="Gcolor animated">
                          <div class="TileContentS">
                            <TileSub>
                              <i
                                class="fas fa-download"
                                style={{ marginRight: "8px" }}
                              ></i>
                              Capstone Report 01
                            </TileSub>
                          </div>
                        </Tile>
                      </a>
                    </Fade>
                    <Fade bottom delay="300" distance="20px">
                      <a
                        href={PdfTwo}
                        target="_blank"
                        style={{ textDecoration: "none" }}
                      >
                        <Tile id="Tilebtn" className="Gcolor animated">
                          <div class="TileContentS">
                            <TileSub>
                              <i
                                class="fas fa-download"
                                style={{ marginRight: "8px" }}
                              ></i>
                              Capstone Report 02
                            </TileSub>
                          </div>
                        </Tile>
                      </a>
                    </Fade>
                  </ReportFrame>
                </ContentFrame>
              </div>
            </div>
            <div className="CaseIntro">
              <ContentFrame>
                <div id="intro">
                  <Fade bottom delay="450" distance="20px">
                    <Title className="header animated">Slumber</Title>
                  </Fade>
                  <Fade bottom delay="500" distance="20px">
                    <LightSubtitle>
                      Improving sleep & glucose management
                    </LightSubtitle>
                  </Fade>
                  <Fade bottom delay="500" distance="20px">
                    <LightText>
                      Developed as my capstone project, Slumber is a mobile IOS
                      application that aims to ease sleep barriers for people
                      with type 01 diabetes struggling to maintain a healthy
                      sleep routine. Achieving this with the use of actionable
                      insight provided by data collected through wearables and
                      glucose management tools.
                    </LightText>
                  </Fade>
                </div>
              </ContentFrame>
            </div>
          </DesktopSlumber>
        </MediaQuery>
        <MediaQuery maxDeviceWidth={767}>
          <MobileSlumber>
            <div className="CaseContain">
              <SmallWave></SmallWave>
              <div className="Process">
                <ContentFrame style={{ paddingTop: "5vh" }}>
                  <Fade bottom delay="250" distance="20px">
                    <SubtitleTwo className="header animated">
                      The Challenge
                    </SubtitleTwo>
                  </Fade>
                  <Fade bottom delay="250" distance="20px">
                    <DarkSubtitle>
                      Design & develop a solution that helps type 01 diabetics
                      improve their sleep.
                    </DarkSubtitle>
                  </Fade>
                  <Fade bottom delay="250" distance="20px">
                    <Text>
                      While there are many sleep tracking applications
                      available, there is no current solution that accounts for
                      factors such as blood glucose levels. Therefore, this
                      project's goal was to create a sleep tracking solution
                      that better matches the needs of diagnosed type 01
                      diabetics. This application would require a customizable &
                      easy use experience tailored to the users' needs and
                      management tools.
                    </Text>
                  </Fade>
                </ContentFrame>
              </div>
              <Preview>
                <PreviewImage src={PreviewImg}></PreviewImage>
              </Preview>
              <div className="Process" style={{ paddingTop: "20vh" }}>
                <ContentFrame>
                  <Fade bottom delay="250" distance="20px">
                    <SubtitleTwo className="header animated">
                      Pain Points
                    </SubtitleTwo>
                  </Fade>
                  <Fade bottom delay="250" distance="20px">
                    <DarkSubtitle>Glucose management is tricky</DarkSubtitle>
                  </Fade>
                  <Fade bottom delay="250" distance="20px">
                    <Text>
                      For people with type 01 diabetes, glucose management is an
                      everyday task, accounting for many different variables to
                      avoid high or low blood sugar levels. To further
                      understand the audience's barriers, I conducted secondary
                      and primary research providing the following insights:
                    </Text>
                  </Fade>
                  <Insights>
                    <Fade bottom delay="250" distance="20px">
                      <InsightItem>
                        <InsightImage className="InsightOne"></InsightImage>
                        <InsightText>
                          Poor sleep lowers insulin efficiency and high or low
                          glucose levels affect sleep.
                        </InsightText>
                      </InsightItem>
                    </Fade>
                    <Fade bottom delay="350" distance="20px">
                      <InsightItem>
                        <InsightImage className="InsightTwo"></InsightImage>
                        <InsightText>
                          Real-time glucose management is often the most
                          difficult to achieve.
                        </InsightText>
                      </InsightItem>
                    </Fade>
                    <Fade bottom delay="450" distance="20px">
                      <InsightItem>
                        <InsightImage className="InsightThree"></InsightImage>
                        <InsightText>
                          People with type 01 diabetes develop routines based
                          off past experiences.
                        </InsightText>
                      </InsightItem>
                    </Fade>
                  </Insights>
                  <Spacer></Spacer>
                  <Fade bottom delay="250" distance="20px">
                    <SubtitleTwo className="header animated">
                      Market Analysis
                    </SubtitleTwo>
                  </Fade>
                  <Fade bottom delay="250" distance="20px">
                    <DarkSubtitle>What’s currently missing?</DarkSubtitle>
                  </Fade>
                  <Fade bottom delay="250" distance="20px">
                    <Text>
                      I conducted a market analysis based on my user research to
                      determine what is available and what is missing for those
                      looking to manage blood glucose levels and
                      sleep—visualizing this in the table below.
                    </Text>
                  </Fade>
                  <Fade bottom delay="250" distance="20px">
                    <ImageM src={Analysis}></ImageM>
                  </Fade>
                  <Spacer></Spacer>
                  <Fade bottom delay="250" distance="20px">
                    <SubtitleTwo className="header animated">
                      Persona Spectrum
                    </SubtitleTwo>
                  </Fade>
                  <Fade bottom delay="250" distance="20px">
                    <DarkSubtitle>
                      Busy diabetics struggle with sleep
                    </DarkSubtitle>
                  </Fade>
                  <Fade bottom delay="250" distance="20px">
                    <Text>
                      Taking the interview results into account, I created a
                      persona spectrum to determine the variety of goals,
                      motivation, and technology of the target audience.
                    </Text>
                  </Fade>
                  <Fade bottom delay="250" distance="20px">
                    <ImageM src={Spectrum}></ImageM>
                  </Fade>
                  <Spacer></Spacer>
                  <Fade bottom delay="250" distance="20px">
                    <SubtitleTwo className="header animated">
                      Storyboarding
                    </SubtitleTwo>
                  </Fade>
                  <Fade bottom delay="250" distance="20px">
                    <DarkSubtitle>AI could improve sleep quality</DarkSubtitle>
                  </Fade>
                  <Fade bottom delay="250" distance="20px">
                    <Text style={{ paddingBottom: "22px" }}>
                      Creating a storyboard, I determined that the application
                      would require basic AI to provide actionable suggestions
                      through user interactions.
                    </Text>
                  </Fade>
                  <Fade bottom delay="250" distance="20px">
                    <ImageM src={Story}></ImageM>
                  </Fade>
                  <Spacer style={{ paddingBottom: "22px" }}></Spacer>
                  <Fade bottom delay="250" distance="20px">
                    <SubtitleTwo className="header animated">
                      User Flows
                    </SubtitleTwo>
                  </Fade>
                  <Fade bottom delay="250" distance="20px">
                    <DarkSubtitle>
                      Visualizing glucose & sleep routines
                    </DarkSubtitle>
                  </Fade>
                  <Fade bottom delay="450" distance="20px">
                    <ImageM src={Flow}></ImageM>
                  </Fade>
                  <Fade bottom delay="250" distance="20px">
                    <Text style={{ paddingTop: "22px" }}>
                      Supporting the target audience, I designed the application
                      MVP to have three main use cases. These included
                      connecting management tools, viewing sleep patterns, and
                      updating profile information.
                    </Text>
                  </Fade>
                  <Spacer></Spacer>
                  <Fade bottom delay="250" distance="20px">
                    <SubtitleTwo className="header animated">
                      Site Mapping
                    </SubtitleTwo>
                  </Fade>
                  <Fade bottom delay="250" distance="20px">
                    <DarkSubtitle>Personalizing recommendations</DarkSubtitle>
                  </Fade>
                  <Fade bottom delay="250" distance="20px">
                    <ImageM src={SMap}></ImageM>
                  </Fade>
                  <Fade bottom delay="250" distance="20px">
                    <Text style={{ paddingTop: "22px" }}>
                      Integrating various data sets, I created a sitemap to
                      visualize how the application would implement personalized
                      data. Providing further insight into the architecture of
                      the solution.
                    </Text>
                  </Fade>
                </ContentFrame>
              </div>
              <PreviewL>
                <ContentFrame>
                  <Fade bottom delay="250" distance="20px">
                    <SubtitleTwo className="header animated">
                      Wireframing & A | B testing
                    </SubtitleTwo>
                  </Fade>
                  <Fade bottom delay="250" distance="20px">
                    <LSubtitle style={{ textAlign: "left" }}>
                      Building customized solutions
                    </LSubtitle>
                  </Fade>
                  <Fade bottom delay="250" distance="20px">
                    <ImageM src={Wires}></ImageM>
                  </Fade>
                  <Fade bottom delay="250" distance="20px">
                    <LText style={{ paddingTop: "22px" }}>
                      Gathering user feedback, I created multiple early
                      wireframes to conduct A/B testing. Resulting in critical
                      future design feedback, including the importance of color
                      choice and the need to design clear data visualizations.
                    </LText>
                  </Fade>
                </ContentFrame>
              </PreviewL>
              <div
                className="Process"
                style={{ paddingTop: "15vh", paddingBottom: "20vh" }}
              >
                <ContentFrame>
                  <Fade bottom delay="250" distance="20px">
                    <SubtitleTwo className="header animated">
                      High-fidelity Mockups
                    </SubtitleTwo>
                  </Fade>
                  <Fade bottom delay="250" distance="20px">
                    <DarkSubtitle>Working on early prototype</DarkSubtitle>
                  </Fade>
                  <Fade bottom delay="250" distance="20px">
                    <Text>
                      Implementing the gathered feedback, the next step of this
                      project is to begin the early prototype design phase.
                      Focused on exploring data visualization, style, and color
                      that can efficiently solve for the target audience.
                    </Text>
                  </Fade>
                  <Fade bottom delay="250" distance="20px">
                    <ImageM src={Proto}></ImageM>
                  </Fade>
                  <Spacer></Spacer>
                  <Fade bottom delay="250" distance="20px">
                    <SubtitleTwo className="header animated">
                      Feedback
                    </SubtitleTwo>
                  </Fade>
                  <Fade bottom delay="250" distance="20px">
                    <DarkSubtitle>Updating the designs</DarkSubtitle>
                  </Fade>
                  <Fade bottom delay="250" distance="20px">
                    <Text>
                      Using the previous phase in a cognitive walkthrough, I
                      significantly updated my designs to reflect the project's
                      goals better. In particular, I focused on the daily
                      summary and creating a call to action that would help the
                      user improve their routine.
                    </Text>
                  </Fade>
                  <Fade bottom delay="250" distance="20px">
                    <ImageM src={Complete}></ImageM>
                  </Fade>
                  <Spacer></Spacer>
                  <Fade bottom delay="250" distance="20px">
                    <SubtitleTwo className="header animated">
                      Project Outcome
                    </SubtitleTwo>
                  </Fade>
                  <Fade bottom delay="250" distance="20px">
                    <DarkSubtitle>Design Reflection</DarkSubtitle>
                  </Fade>
                  <Fade bottom delay="250" distance="20px">
                    <Text>
                      Overall, Slumber was able to achieve the goals related to
                      creating strong visual comparisons between glucose and
                      sleep routines. In the future, this solution would look to
                      explore IOS components such as widgets, push
                      notifications, and apple watch designs to better the
                      overall user experience. However, in it's current state,
                      Slumber is a solid example of how we can use current
                      technologies to improve people's ability to manage their
                      health and routines.<br></br>
                      <br></br>
                      For more information, feel free to download my capstone
                      report iterations.
                    </Text>
                  </Fade>
                  <ReportFrame>
                    <Fade bottom delay="250" distance="20px">
                      <a
                        href={PdfOne}
                        target="_blank"
                        style={{ textDecoration: "none" }}
                      >
                        <Tile id="Tilebtn" className="Gcolor animated">
                          <TileContentS>
                            <TileSub>
                              <i
                                class="fas fa-download"
                                style={{ marginRight: "8px" }}
                              ></i>
                              Capstone Report 01
                            </TileSub>
                          </TileContentS>
                        </Tile>
                      </a>
                    </Fade>
                    <Fade bottom delay="300" distance="20px">
                      <a
                        href={PdfTwo}
                        target="_blank"
                        style={{ textDecoration: "none" }}
                      >
                        <Tile id="Tilebtn" className="Gcolor animated">
                          <TileContentS>
                            <TileSub>
                              <i
                                class="fas fa-download"
                                style={{ marginRight: "8px" }}
                              ></i>
                              Capstone Report 02
                            </TileSub>
                          </TileContentS>
                        </Tile>
                      </a>
                    </Fade>
                  </ReportFrame>
                </ContentFrame>
              </div>
            </div>
            <div className="CaseIntro">
              <ContentFrame>
                <div id="intro">
                  <Fade bottom delay="450" distance="20px">
                    <Title className="header animated">Slumber</Title>
                  </Fade>
                  <Fade bottom delay="500" distance="20px">
                    <LightSubtitle>
                      Improving sleep & glucose management
                    </LightSubtitle>
                  </Fade>
                  <Fade bottom delay="500" distance="20px">
                    <LightText>
                      Developed as my capstone project, Slumber is a mobile IOS
                      application that aims to ease sleep barriers for people
                      with type 01 diabetes struggling to maintain a healthy
                      sleep routine. Achieving this with the use of actionable
                      insight provided by data collected through wearables and
                      glucose management tools.
                    </LightText>
                  </Fade>
                </div>
              </ContentFrame>
            </div>
          </MobileSlumber>
        </MediaQuery>
      </SlumberContent>
    );
  }
}

export default Slumber;
