import React, { Component } from "react";
import styled from "styled-components";

import ScrollIcon from "./scroller";
import Fade from "react-reveal/Fade";

const Flow = styled.div`
  left: 0px;
  right: 0px;
  bottom: 0px;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-end;
  z-index: 3;
`;

class SmallWave extends Component {
  render() {
    return (
      <Flow>
        <Fade bottom delay="500" distance="20px">
          <ScrollIcon className="testing"></ScrollIcon>
        </Fade>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          viewBox="0 0 1919.231 218.353"
          style={{ marginBottom: "-1vh" }}
        >
          <path
            id="Path_14"
            data-name="Path 14"
            d="M1957.5,1492.935s1192.027,90.659,1919.228-86.822v218.353H1957.5Z"
            transform="translate(-1957.497 -1406.113)"
            fill="#fff"
          />
        </svg>
      </Flow>
    );
  }
}

export default SmallWave;
