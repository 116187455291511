import React, { Component } from "react";
import styled from "styled-components";

const Flow = styled.div`
  position: fixed;
  left: 0px;
  right: 0px;
  bottom: 0px;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-end;
  z-index: 3;
`;

const Space = styled.div`
  width: 100%;
  background-color: white;
`;

class AnimatedBackground extends Component {
  render() {
    return (
      <Flow>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          viewBox="0 0 1919.231 218.353"
          style={{ marginBottom: "-1vh" }}
        >
          <path
            id="Path_14"
            data-name="Path 14"
            d="M1957.5,1492.935s1192.027,90.659,1919.228-86.822v218.353H1957.5Z"
            transform="translate(-1957.497 -1406.113)"
            fill="#fff"
          />
        </svg>
        <Space id="white"></Space>
      </Flow>
    );
  }
}

export default AnimatedBackground;
