import React, { Component } from "react";
import MediaQuery from "react-responsive";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import Fade from "react-reveal/Fade";
import { device } from "../device.js";

import Resume from "../components/AdamCrosbyCV.pdf";

const DFoot = styled.div`
  width: 100%;
  height: 56px;
  z-index: 99999;
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
`;

const FootContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  align-items: center;
  box-sizing: border-box;
  font-family: "Lato", sans-serif;

  @media ${device.tablet} {
    padding-left: 8%;
    padding-right: 8%;
  }
  @media ${device.laptop} {
    padding-left: 15%;
    padding-right: 15%;
  }
  @media ${device.laptopL} {
    padding-left: 15%;
    padding-right: 15%;
  }
  @media ${device.desktop} {
    padding-left: 15%;
    padding-right: 15%;
  }
  @media ${device.desktopL} {
    padding-left: 15%;
    padding-right: 15%;
  }
`;

const FootLeft = styled.div`
  display: flex;
  flex-direction: row;
`;

const FootRight = styled.div`
  display: flex;
  flex-direction: row;
`;

const FootLink = styled.a`
  font-size: 10px;
  margin-right: 32px;
  color: #0a0a0a;
  text-decoration: none;
  transition: ease-in-out 250ms;

  :hover {
    color: #cc43a0;
  }
`;

const FootTag = styled.p`
  margin: 0;
  font-size: 10px;
  color: #0a0a0a;
`;

const FootIcon = styled.a`
  font-size: 12px;
  color: #0a0a0a;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  transition: ease-in-out 250ms;
  margin-left: 16px;

  :hover {
    color: #cc43a0;
  }
`;

class DesktopFooter extends Component {
  render() {
    return (
      <DFoot>
        <FootContent>
          <Fade>
            <FootLeft>
              <FootLink href="mailto:crosbyadesign@gmail.com">
                crosbyadesign@gmail.com
              </FootLink>
              <FootLink href={Resume} target="_blank">
                CV / Resume
              </FootLink>
              <FootTag>© crosbya 2021</FootTag>
            </FootLeft>
          </Fade>
          <Fade>
            <FootRight>
              <FootTag style={{ paddingRight: "32px" }}>
                <i class="fab fa-react" style={{ paddingRight: "8px" }}></i>
                Built in React.js
              </FootTag>
              <FootIcon href="mailto:crosbyadesign@gmail.com">
                <i class="fas fa-comment"></i>
              </FootIcon>
              <FootIcon href="https://www.linkedin.com/in/adam-w-crosby">
                <i class="fab fa-linkedin-in"></i>
              </FootIcon>
              <FootIcon href="https://www.instagram.com/acrosby93">
                <i class="fab fa-instagram"></i>
              </FootIcon>
            </FootRight>
          </Fade>
        </FootContent>
      </DFoot>
    );
  }
}

/*Mobile Footer*/

const MFoot = styled.div`
  width: 100%;
  height: 60px;
  z-index: 99999;
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const MFootIcon = styled.a`
  font-size: 22px;
  color: #0a0a0a;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  transition: ease-in-out 250ms;
  margin-left: 22px;
  margin-right: 22px;

  :hover {
    color: #cc43a0;
  }
`;

class MobileFoot extends Component {
  render() {
    return (
      <MFoot>
        <MFootIcon href="mailto:crosbyadesign@gmail.com">
          <i class="fas fa-comment"></i>
        </MFootIcon>
        <MFootIcon href="https://www.linkedin.com/in/adam-w-crosby">
          <i class="fab fa-linkedin-in"></i>
        </MFootIcon>
        <MFootIcon href="https://www.instagram.com/acrosby93">
          <i class="fab fa-instagram"></i>
        </MFootIcon>
      </MFoot>
    );
  }
}

function Footer() {
  return (
    <div>
      <MediaQuery minDeviceWidth={768}>
        <DesktopFooter></DesktopFooter>
      </MediaQuery>
      <MediaQuery maxDeviceWidth={767}>
        <div></div>
      </MediaQuery>
    </div>
  );
}

export default Footer;
