import React, { Component } from "react";
import MediaQuery from "react-responsive";
import styled from "styled-components";
import Fade from "react-reveal/Fade";
import { NavLink } from "react-router-dom";
import { device } from "../device.js";

const HomeContent = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  @media ${device.tablet} {
    padding-bottom: 80px;
  }
  @media ${device.laptop} {
    padding-bottom: 40px;
  }
  @media ${device.laptopL} {
    padding-bottom: 40px;
  }
  @media ${device.desktop} {
  }
  @media ${device.desktopL} {
  }
`;

const DesktopHome = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const MobileHome = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding-bottom: 100px;
`;

const HomeText = styled.div`
  @media ${device.mobileS} {
    width: 100%;
  }
  @media ${device.mobileM} {
    width: 100%;
  }
  @media ${device.mobileL} {
    width: 100%;
  }
  @media ${device.tablet} {
    width: 60%;
  }
  @media ${device.laptop} {
    width: 60%;
  }
  @media ${device.laptopL} {
    width: 60%;
  }
  @media ${device.desktop} {
    width: 60%;
  }
  @media ${device.desktopL} {
    width: 60%;
  }
`;

const Title = styled.h1`
  color: white;
  margin: 0;
  padding: 0;
  letter-spacing: 1px;
  font-family: "Montserrat", sans-serif;

  @media ${device.mobileS} {
    font-size: 44px;
    line-height: 66px;
  }
  @media ${device.mobileM} {
    font-size: 44px;
    line-height: 66px;
  }
  @media ${device.mobileL} {
    font-size: 44px;
    line-height: 66px;
  }
  @media ${device.tablet} {
    font-size: 66px;
    line-height: 88px;
  }
  @media ${device.laptop} {
    font-size: 76px;
    line-height: 88px;
  }
  @media ${device.laptopL} {
    font-size: 76px;
    line-height: 108px;
  }
  @media ${device.desktop} {
    font-size: 88px;
    line-height: 88px;
  }
  @media ${device.desktopL} {
    font-size: 88px;
    line-height: 88px;
  }
`;

const SubText = styled.p`
  color: white;
  margin-bottom: 36px;

  @media ${device.mobileS} {
    font-size: 16px;
    line-height: 32px;
  }
  @media ${device.mobileM} {
    font-size: 18px;
    line-height: 36px;
  }
  @media ${device.mobileL} {
    font-size: 18px;
    line-height: 36px;
  }
  @media ${device.tablet} {
    font-size: 18px;
    line-height: 36px;
  }
  @media ${device.laptop} {
    font-size: 22px;
    line-height: 44px;
  }
  @media ${device.laptopL} {
    font-size: 22px;
    line-height: 44px;
  }
  @media ${device.desktop} {
  }
  @media ${device.desktopL} {
  }
`;

const PrimaryBtn = styled.div`
  width: fit-content;
`;

class Home extends Component {
  componentDidMount() {
    var height = document.getElementById("white");
    height.classList.add("HHeight");
    height.classList.remove("AHeight");
    height.classList.remove("WHeight");
    height.classList.remove("PHeight");
  }
  render() {
    return (
      <HomeContent>
        <MediaQuery minDeviceWidth={768}>
          <DesktopHome>
            <HomeText>
              <Fade bottom delay="450" distance="20px">
                <Title>
                  Hi, I’m <br></br>
                  <span class="header animated">Adam Crosby</span>
                </Title>
              </Fade>
              <Fade bottom delay="650" distance="20px">
                <SubText>
                  A digital product designer helping make science-fiction
                  reality.
                </SubText>
              </Fade>
              <Fade bottom delay="850" distance="20px">
                <NavLink exact to="/Work" style={{ textDecoration: "none" }}>
                  <PrimaryBtn id="btn" className="Gcolor animated">
                    <div class="btnContent">PAST WORK</div>
                  </PrimaryBtn>
                </NavLink>
              </Fade>
            </HomeText>
          </DesktopHome>
        </MediaQuery>
        <MediaQuery maxDeviceWidth={767}>
          <MobileHome>
            <HomeText>
              <Fade bottom delay="450" distance="20px">
                <Title>
                  Hi, I’m <br></br>
                  <span class="header animated">Adam Crosby</span>
                </Title>
              </Fade>
              <Fade bottom delay="650" distance="20px">
                <SubText>
                  A digital product designer helping make science-fiction
                  reality.
                </SubText>
              </Fade>
              <Fade bottom delay="850" distance="20px">
                <NavLink exact to="/Work" style={{ textDecoration: "none" }}>
                  <PrimaryBtn id="btn" className="Gcolor animated">
                    <div class="btnContent">Past Work</div>
                  </PrimaryBtn>
                </NavLink>
              </Fade>
            </HomeText>
          </MobileHome>
        </MediaQuery>
      </HomeContent>
    );
  }
}

export default Home;
