import React, { Component } from "react";
import MediaQuery from "react-responsive";
import styled from "styled-components";
import Fade from "react-reveal/Fade";
import { NavLink } from "react-router-dom";
import { device } from "../device.js";
import WbPhone from "../components/webPhone.js";
import { Frame, Page } from "framer";

import Swiper from "../components/workSwipe.js";

import PhoneBG from "../images/Iphone.png";
import PhoneEdge from "../images/phoneFrame.png";

import ReactDOM from "react-dom";

import PreviewImg from "../images/slumber/temp.png";
import PreAppetizr from "../images/Appetizr/AppetizrPre.png";
import PreTalk from "../images/Talky/TalkyM.png";

import Melody from "../pdfs/ASF4-FutureLionsContestSubmission_AdamCrosby_AnushDesai_IsabelleVohsemer_WilliamTruong_2021-03-31.pdf";

const WorkContent = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  @media ${device.tablet} {
    padding-bottom: 80px;
  }
  @media ${device.laptop} {
    padding-bottom: 40px;
  }
  @media ${device.laptopL} {
    padding-bottom: 40px;
  }
  @media ${device.desktop} {
  }
  @media ${device.desktopL} {
  }
`;

const DesktopWork = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;

  @media ${device.tablet} {
    padding-top: 44px;
  }
  @media ${device.laptop} {
    padding-top: 44px;
  }
  @media ${device.laptopL} {
    padding-top: 44px;
  }
  @media ${device.desktop} {
  }
  @media ${device.desktopL} {
  }
`;

const MobileWork = styled.div`
  max-width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding-top: 60px;
`;

const WorkText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;

  @media ${device.tablet} {
    width: 60%;
  }
  @media ${device.laptop} {
    width: 60%;
  }
  @media ${device.laptopL} {
    width: 60%;
  }
  @media ${device.desktop} {
    width: 60%;
  }
  @media ${device.desktopL} {
    width: 60%;
  }
`;

const ImageSection = styled.div`
  width: 40%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
`;

const Title = styled.h1`
  color: white;
  margin: 0;
  padding: 0;
  letter-spacing: 1px;
  font-family: "Montserrat", sans-serif;

  @media ${device.mobileS} {
    font-size: 36px;
    line-height: 44px;
  }
  @media ${device.mobileM} {
    font-size: 36px;
    line-height: 44px;
  }
  @media ${device.mobileL} {
    font-size: 36px;
    line-height: 44px;
  }
  @media ${device.tablet} {
    font-size: 66px;
    line-height: 88px;
  }
  @media ${device.laptop} {
    font-size: 76px;
    line-height: 88px;
  }
  @media ${device.laptopL} {
    font-size: 76px;
    line-height: 108px;
  }
  @media ${device.desktop} {
    font-size: 88px;
    line-height: 88px;
  }
  @media ${device.desktopL} {
    font-size: 88px;
    line-height: 88px;
  }
`;

const MobileSub = styled.p`
  color: white;
  margin: 0;
  padding: 0;
  font-size: 14px;
  margin-top: 6px;
  margin-bottom: 22px;
`;

const TitleFrame = styled.div`
  width: 100%;
  height: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ListFrame = styled.div`
  width: 100%;
  height: 65%;
  margin-top: 5%;
`;

const Tile = styled.div`
  width: 80%;
  margin-top: 22px;
  box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.075);
  cursor: pointer;

  :hover {
    box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.035);
  }
`;

const TileTitle = styled.h2`
  font-size: 26px;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  margin: 0;
  padding: 0;
  text-align: left;
  width: 100%;
  box-sizing: border-box;
  padding-left: 26px;
`;

const TileSub = styled.p`
  font-size: 16px;
  margin: 0;
  padding: 0;
  text-align: left;
  width: 100%;
  box-sizing: border-box;
  padding-left: 26px;
  margin-top: 2px;
  font-family: "Lato", sans-serif;
  font-weight: 400;
`;

const TileContent = styled.div`
  width: 100%;
  text-align: center;
  padding: 16px 22px 18px 0px;
  background-color: #f7f7f7;
  color: black;
  transition: ease-in-out 250ms;
  font-size: 12px;
  border-radius: 8px;
  @media ${device.mobileS} {
  }
  @media ${device.mobileM} {
  }
  @media ${device.mobileL} {
  }
  @media ${device.tablet} {
    background-color: #f7f7f7;
    color: black;
  }
  @media ${device.laptop} {
    :hover {
      background-color: rgba(0, 0, 0, 0);
      color: white;
    }
  }
  @media ${device.laptopL} {
    :hover {
      background-color: rgba(0, 0, 0, 0);
      color: white;
    }
  }
  @media ${device.desktop} {
    :hover {
      background-color: rgba(0, 0, 0, 0);
      color: white;
    }
  }
  @media ${device.desktopL} {
    :hover {
      background-color: rgba(0, 0, 0, 0);
      color: white;
    }
  }
`;

const SlideFrame = styled.div`
  width: 100%;
  height: 65vh;
  display: flex;
  place-content: center;
  place-items: center;
  margin: 0;
  padding: 0;
  perspective: 1000px;
  margin-top: 8px;
`;

const Slide = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
`;

const SlideImg = styled.img`
  height: 65%;
`;

const SlideTitle = styled.h1`
  font-size: 22px;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  margin: 0;
  padding: 22px 0px 8px 0px;
`;

const SlideSub = styled.p`
  font-size: 16px;
  margin: 0;
  padding: 0;
`;

const SlideBtn = styled.div`
  margin-top: 26px;
  box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.075);
`;

class Work extends Component {
  componentDidMount() {
    var height = document.getElementById("white");
    height.classList.add("WHeight");
    height.classList.remove("AHeight");
    height.classList.remove("HHeight");
    height.classList.remove("PHeight");
  }
  DefaultScreen() {
    var screen = document.getElementById("PhoneScreen");
    screen.classList.remove("Slumber");
    screen.classList.remove("Appetizr");
    screen.classList.remove("VitaBand");
  }
  TileOne() {
    var screen = document.getElementById("PhoneScreen");
    screen.classList.add("Slumber");
    screen.classList.remove("Appetizr");
    screen.classList.remove("VitaBand");
  }
  TileTwo() {
    var screen = document.getElementById("PhoneScreen");
    screen.classList.remove("Slumber");
    screen.classList.add("Appetizr");
    screen.classList.remove("VitaBand");
  }
  TileThree() {
    var screen = document.getElementById("PhoneScreen");
    screen.classList.remove("Slumber");
    screen.classList.remove("Appetizr");
    screen.classList.add("VitaBand");
  }

  render() {
    return (
      <WorkContent>
        <MediaQuery minDeviceWidth={768}>
          <DesktopWork>
            <WorkText>
              <TitleFrame>
                <Fade bottom delay="450" distance="20px">
                  <Title>Past Work</Title>
                </Fade>
              </TitleFrame>
              <ListFrame>
                <Fade bottom delay="850" distance="20px">
                  <NavLink
                    exact
                    to="/Slumber"
                    style={{ textDecoration: "none" }}
                  >
                    <Tile
                      id="Tilebtn"
                      className="Gcolor animated"
                      onMouseOver={this.TileOne}
                      onMouseLeave={this.DefaultScreen}
                    >
                      <TileContent>
                        <TileTitle>Slumber</TileTitle>
                        <TileSub>Improving sleep & glucose management</TileSub>
                      </TileContent>
                    </Tile>
                  </NavLink>
                </Fade>
                <Fade bottom delay="1050" distance="20px">
                  <NavLink
                    exact
                    to="/Appetizr"
                    style={{ textDecoration: "none" }}
                  >
                    <Tile
                      id="Tilebtn"
                      className="Gcolor animated"
                      onMouseOver={this.TileTwo}
                      onMouseLeave={this.DefaultScreen}
                    >
                      <TileContent>
                        <TileTitle>Appetizr</TileTitle>
                        <TileSub>
                          Re-defining the recipe sharing experience.
                        </TileSub>
                      </TileContent>
                    </Tile>
                  </NavLink>
                </Fade>
                <a
                  exact
                  href={Melody}
                  target="_blank"
                  style={{ textDecoration: "none" }}
                >
                  <Fade bottom delay="1250" distance="20px">
                    <Tile
                      id="Tilebtn"
                      className="Gcolor animated"
                      onMouseOver={this.TileThree}
                      onMouseLeave={this.DefaultScreen}
                    >
                      <TileContent>
                        <TileTitle>Lego Melody</TileTitle>
                        <TileSub>
                          (Case study coming soon) Click to view PDF
                        </TileSub>
                      </TileContent>
                    </Tile>
                  </Fade>
                </a>
              </ListFrame>
            </WorkText>
            <ImageSection className="fade-in">
              <WbPhone></WbPhone>
            </ImageSection>
          </DesktopWork>
        </MediaQuery>
        <MediaQuery maxDeviceWidth={767}>
          <MobileWork>
            <Fade bottom delay="450" distance="20px">
              <Title>Past Work</Title>
            </Fade>
            <Fade bottom delay="650" distance="20px">
              <MobileSub className="header animated">Swipe to browse</MobileSub>
            </Fade>
            <Fade bottom delay="850" distance="20px">
              <SlideFrame>
                <Page
                  width={"100%"}
                  height={"100%"}
                  radius={0}
                  defaultEffect={"coverflow"}
                  gap={0}
                  momentum
                  wheelEnabled={true}
                >
                  <Slide>
                    <SlideImg src={PreviewImg}></SlideImg>
                    <Fade bottom delay="1050" distance="20px">
                      <SlideTitle className="header animated">
                        Slumber
                      </SlideTitle>
                    </Fade>
                    <Fade bottom delay="1250" distance="20px">
                      <SlideSub>Improving sleep & Glucose Management</SlideSub>
                    </Fade>
                    <Fade bottom delay="1450" distance="20px">
                      <NavLink
                        exact
                        to="/Slumber"
                        style={{ textDecoration: "none" }}
                      >
                        <SlideBtn id="btn" className="Gcolor animated">
                          <div class="btnContent">View Case Study</div>
                        </SlideBtn>
                      </NavLink>
                    </Fade>
                  </Slide>
                  <Slide>
                    <SlideImg src={PreAppetizr}></SlideImg>
                    <Fade bottom delay="1050" distance="20px">
                      <SlideTitle className="header animated">
                        Appetizr
                      </SlideTitle>
                    </Fade>
                    <Fade bottom delay="1250" distance="20px">
                      <SlideSub>
                        Re-defining the recipe sharing experience.
                      </SlideSub>
                    </Fade>
                    <Fade bottom delay="1450" distance="20px">
                      <NavLink
                        exact
                        to="/Appetizr"
                        style={{ textDecoration: "none" }}
                      >
                        <SlideBtn id="btn" className="Gcolor animated">
                          <div class="btnContent">View Case Study</div>
                        </SlideBtn>
                      </NavLink>
                    </Fade>
                  </Slide>
                  <Slide>
                    <SlideImg
                      style={{ height: "65%" }}
                      src={PreTalk}
                    ></SlideImg>
                    <Fade bottom delay="1050" distance="20px">
                      <SlideTitle className="header animated">
                        Talky Blocks
                      </SlideTitle>
                    </Fade>
                    <Fade bottom delay="1250" distance="20px">
                      <SlideSub>Learning to spell through play.</SlideSub>
                    </Fade>
                    <Fade bottom delay="1450" distance="20px">
                      <NavLink
                        exact
                        to="/TalkyBlocks"
                        style={{ textDecoration: "none" }}
                      >
                        <SlideBtn id="btn" className="Gcolor animated">
                          <div class="btnContent">View Case Study</div>
                        </SlideBtn>
                      </NavLink>
                    </Fade>
                  </Slide>
                </Page>
              </SlideFrame>
            </Fade>
          </MobileWork>
        </MediaQuery>
      </WorkContent>
    );
  }
}

export default Work;
